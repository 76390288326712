import React, { useEffect } from 'react';
import DynamicList from '../../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import RestoreIcon from '@mui/icons-material/Restore';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Swal from 'sweetalert2';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Place,
  useDeletePlaceMutation,
  useHardDeletePlaceMutation,
  useRestorePlaceMutation,
  useGetPlacesLazyQuery,
  refetchGetPlacesQuery,
} from '../../../graphql';
import { selectedPlace } from '../../../cache';
import { useExportPlaceMutation, useImportPlaceMutation } from '../../../graphql';
import { checkPermission } from '../../../utils/check-permission';

const PlaceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canExportPlaces = checkPermission('create_places_all');

  const [selected, setSelected] = React.useState<Place>(null);
  const [inactivePlaces, setInactivePlaces] = React.useState<boolean>(false);

  const [getPlaces, { loading, data }] = useGetPlacesLazyQuery();

  const [deletePlace, { loading: deleteLoading }] = useDeletePlaceMutation({
    refetchQueries: [refetchGetPlacesQuery({ inactive: inactivePlaces })],
    awaitRefetchQueries: true,
  });
  const [hardDeletePlace, { loading: hardDeleteLoading }] = useHardDeletePlaceMutation({
    refetchQueries: [refetchGetPlacesQuery({ inactive: inactivePlaces })],
    awaitRefetchQueries: true,
  });
  const [restorePlace] = useRestorePlaceMutation({
    refetchQueries: [refetchGetPlacesQuery({ inactive: inactivePlaces })],
    awaitRefetchQueries: true,
  });

  const [exportedPlace, setExportedPlace] = React.useState<string>('');
  const [exportPlace, { loading: exportLoading }] = useExportPlaceMutation({
    onCompleted(resp: any) {
      const {exportPlace: { data }} = resp;
      downloadExportedFile(data)
    }
  });
  const [importPlace, { loading: importLoading }] = useImportPlaceMutation({
    onCompleted(resp: any) {
      const {importPlace: { id }} = resp;
      navigate(`/places/${id}/view`);
    }
  });

  const downloadExportedFile = (data: string) => {
    const element = document.createElement('a');
    const file = new Blob([data], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = `exported_${exportedPlace}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  useEffect(() => {
    getPlaces({ variables: { inactive: inactivePlaces } });
  }, [inactivePlaces]);

  useEffect(() => {
    if (selected) {
      Swal.fire({
        title: t('Are you sure?'),
        text: inactivePlaces ? t('cantRevertAction') : t('reversibleAction'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
      }).then((result: any) => {
        if (result.value) {
          Swal.showLoading();
          inactivePlaces
            ? hardDeletePlace({ variables: { id: selected.id } })
            : deletePlace({ variables: { id: selected.id } });
        }
        setSelected(null);
      });
    }
  }, [selected]);

  const onFileChange = (event: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const json_data: any = JSON.parse(String(reader.result));
        Swal.fire({
            title: 'Confirmar Importación',
            text: !importLoading
              ? `¿Quieres importar el lugar "${json_data.place.name}"?`
              + `(${json_data.locations.length} locations, `
              + `${json_data.maps.length} maps, `
              + `${json_data.pins.length} pins)`
              : `Importando "${json_data.place.name}"...`,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
        }).then((result: any) => {
          if (!result.dismiss) {
            Swal.showLoading();
            importPlace({variables: {data: json_data}})
          }
        });
    } catch (error) {
        console.error('Error al parsear el archivo JSON:', error);
        Swal.fire({
            title: 'Error',
            text: 'El archivo seleccionado no es un JSON válido.',
            icon: 'error',
        });
    }
    };
    reader.readAsText(event.target.files[0]);
};

  const user: any = JSON.parse(localStorage.getItem('user') || '{}');
  const userPlaces = Object.keys(user.permissions || {});

  return (
    <div>
      <DynamicList
        onRowClick={
          inactivePlaces // TODO: remove this condition and allow update inactive places
            ? null
            : (place: any) => {
                localStorage.setItem('selectedPlace', place.id);
                selectedPlace(place.id);
                navigate(`/places/${place.id}/view`);
              }
        }
        loading={loading}
        actions={
          user?.roles?.indexOf('superadmin') >= 0 ? (
            <>
              <Button
                component={Link}
                to="/places/create"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddCircleIcon />}>
                {capitalize(t('create'))}
              </Button>
              <Input
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={onFileChange}
                inputProps={{ 'aria-label': capitalize(t('import')) }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  size="large"
                  startIcon={<ImportExportIcon />}>
                  {capitalize(t('import'))}
                </Button>
              </label>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ ml: '1rem' }}
                      onChange={() => {
                        setInactivePlaces(!inactivePlaces);
                      }}
                    />
                  }
                  label="Mostrar lugares eliminados"
                />
              </FormGroup>
            </>
          ) : (
            <></>
          )
        }
        objectList={
          user?.roles?.indexOf('superadmin') >= 0
            ? data?.places
            : data?.places.filter((place: any) => userPlaces.indexOf(place.id) >= 0)
        }
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => e.name,
          },
          {
            label: t('description'),
            renderFn: (e: any) => e.description,
          },
          {
            label: t('type'),
            renderFn: (e: any) => e.placeType,
          },
          {
            label: t('createdBy'),
            renderFn: (e: any) => e.contact?.displayName,
          },
          {
            label: t('createdAt'),
            renderFn: (e: any) => moment(e.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: t('updatedAt'),
            renderFn: (e: any) => moment(e.updatedAt).format('DD/MM/YYYY'),
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                {inactivePlaces && (
                  <Tooltip title={t('restore')}>
                    <IconButton
                      sx={{ color: colors.blue[700] }}
                      onClick={() => {
                        restorePlace({ variables: { id: e.id } });
                      }}>
                      {<RestoreIcon />}
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => {
                      navigate(`/places/${e.id}/update`);
                    }}>
                    {<EditIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton sx={{ color: colors.red[600] }} onClick={() => setSelected(e)}>
                    {deleteLoading || hardDeleteLoading ? <CircularProgress /> : <DeleteIcon />}
                  </IconButton>
                </Tooltip>
                {canExportPlaces && (
                  <Tooltip title={capitalize(t('export'))}>
                    <IconButton
                      sx={{ color: colors.cyan[600] }}
                      onClick={() => {
                        if (!exportLoading) {
                          setExportedPlace(e.name.replaceAll(' ', '_').toLowerCase());
                          exportPlace({ variables: { id: e.id } });
                        }
                      }}>
                      {deleteLoading || hardDeleteLoading || exportLoading ? <CircularProgress /> : <ImportExportIcon />}
                    </IconButton>
                  </Tooltip>
                )}
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PlaceList;
