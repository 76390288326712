import React from 'react';
import DynamicList from '../../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../../../graphql';

const UserList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { loading, data } = useGetUsersQuery();

  return (
    <div>
      <DynamicList
        loading={loading}
        actions={
          <Button
            component={Link}
            to="/users/create"
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddCircleIcon />}>
            {t('create')}
          </Button>
        }
        objectList={data?.users}
        columns={[
          {
            label: t('email'),
            renderFn: (e: any) => e.email,
          },
          {
            label: t('name'),
            renderFn: (e: any) => e.name,
          },
          {
            label: t('lastName'),
            renderFn: (e: any) => e.lastName,
          },
          {
            label: t('createdAt'),
            renderFn: (e: any) => moment(e.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: t('updatedAt'),
            renderFn: (e: any) => moment(e.updatedAt).format('DD/MM/YYYY'),
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('update')}>
                  <IconButton sx={{ color: colors.yellow[800] }} onClick={() => navigate(`/users/${e.uid}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default UserList;
