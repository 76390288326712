export default {
  capitalize: '{{text,capitalize}}',
  isRequired: '{{field}} es requerido',
  selectField: 'Seleccionar {{field}}',
  changeField: 'Cambiar de {{field}}',
  createTitle: 'Crear {{field}}',
  updateTitle: 'Editar {{field}}',
  selectTitle: 'Seleccionar {{field}}',
  payedPlans: 'solo para planes de pago',
  name: 'nombre',
  description: 'descripción',
  createdAt: 'fecha de creación',
  updatedAt: 'fecha de actualización',
  lastName: 'apellido',
  actions: 'acciones',
  create: 'crear',
  type: 'tipo',
  'Select position': 'Seleccionar posición',
  submit: 'guardar',
  update: 'editar',
  restore: 'restaurar',
  delete: 'eliminar',
  'Are you sure?': 'Estas seguro?',
  cantRevertAction: 'No podrás revertir esta acción!',
  reversibleAction: 'Esto podría revertirse unicamente con ayuda de un administrador',
  Yes: 'Si',
  No: 'No',
  from: 'desde',
  to: 'hasta',
  selectImage: 'Seleccionar imagen',
  multiSelectImage: 'Seleccionar imágenes',
  selectImageDescription: 'Suelta una imagen aquí o haz click para seleccionar una desde tu dispositivo',
  multiSelectImageDescription: 'Suelta una o varias imágenes aquí o haz click para seleccionarlas desde tu dispositivo',
  deleteImage: 'Borrar imagen',
  selectImageMaximumSize: 'Tamaño máximo 50mb',
  images: 'imágenes',
  add: 'añadir',
  dynamicDescriptionExplain:
    'Las descripciones dinámicas se mostrarán al hacer click en el pin correspondiente y el orden en que se agreguen a continuación.\
    El tipo "Descripción", es utilizado para escribir textos que serán mostrados con el mismo formato que se describan.\
    El tipo "Imágenes" es utilizado para subir una o un conjunto de imágenes que se mostraran en estilo carousel.\
    El tipo "Video" es utilizado para subir un link de un video de YouTube en caso de que se quiera agregar como complemento al pin.',
  dynamicDescriptionText: 'descripción',
  dynamicDescriptionCarousel: 'imágenes',
  dynamicDescriptionVideo: 'video',
  search: 'buscar',
  setCurrent: 'Usar ubicación actual',
};
