import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Button, FormLabel, Dialog, DialogTitle, IconButton, colors } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'leaflet/dist/leaflet.css';

type MapPreviewProps = {
  bounds: any,
  title: string,
  children: any,
  customTile?: string,
  mapContainerProps?: any,
}

// @Todo add map typing, its bugged because of point and pointGeo inconsistencies in api schema
const MapModal = (props: MapPreviewProps) => {
  const {
    bounds,
    customTile,
    title,
    children,
    mapContainerProps = {},
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(!open)
        }}
      >
        {title}
      </Button>
      <Dialog fullScreen onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" display={'flex'} alignItems={'center'}>
          {title}
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 'auto' }}
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </DialogTitle>
        <MapContainer
          bounds={bounds}
          maxZoom={21}
          wheelPxPerZoomLevel={180}
          style={{ width: '100%', height: '100vh' }}
          {...mapContainerProps}
        >
          <TileLayer
            maxZoom={19}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={`${process.env.REACT_APP_TILE_SERVER_URL}/{z}/{x}/{y}.png`}
          />
          {customTile && (
            <TileLayer
              maxZoom={21}
              url={customTile}
            />
          )}
          {children}
        </MapContainer>
      </Dialog>
    </>
  );
};
export default MapModal;
