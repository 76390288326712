import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

const ContactForm = (props: {
  onSubmit: (arg0: any) => void;
  loading: boolean;
  title: string;
  defaultValues?: any;
}) => {
  const { onSubmit, loading, title, defaultValues } = props;

  const { t } = useTranslation();
  const schema = yup.object().shape({
    displayName: yup.string().required(t('isRequired', { field: t('displayName') })),
    email: yup.string().email(t('isInvalid', { field: t('email') })),
    phone: yup.string(),
    web: yup.string().url(t('isInvalid', { field: t('web') })),
    instagram: yup.string().url(t('isInvalid', { field: t('instagram') })),
    tikTok: yup.string().url(t('isInvalid', { field: t('tikTok') })),
    linkedin: yup.string().url(t('isInvalid', { field: t('linkedin') })),
    twitter: yup.string().url(t('isInvalid', { field: t('twitter') })),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues || { displayName: '', email: '', phone: '', web: '', instagram: '', tikTok: '', linkedin: '', twitter: '' },
  });

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('displayName'))}
                type="text"
                variant="outlined"
                {...register('displayName')}
                error={Boolean(errors.displayName)}
                helperText={errors.displayName?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('email'))}
                type="email"
                variant="outlined"
                {...register('email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('phone'))}
                type="text"
                variant="outlined"
                {...register('phone')}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('mobile'))}
                type="text"
                variant="outlined"
                {...register('mobile')}
                error={Boolean(errors.mobile)}
                helperText={errors.mobile?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('whatsapp'))}
                type="text"
                variant="outlined"
                {...register('whatsapp')}
                error={Boolean(errors.whatsapp)}
                helperText={errors.whatsapp?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('web'))}
                type="url"
                variant="outlined"
                {...register('web')}
                error={Boolean(errors.web)}
                helperText={errors.web?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('facebook'))}
                type="url"
                variant="outlined"
                {...register('facebook')}
                error={Boolean(errors.facebook)}
                helperText={errors.facebook?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('instagram'))}
                type="url"
                variant="outlined"
                {...register('instagram')}
                error={Boolean(errors.instagram)}
                helperText={errors.instagram?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('tikTok'))}
                type="url"
                variant="outlined"
                {...register('tikTok')}
                error={Boolean(errors.tikTok)}
                helperText={errors.tikTok?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('linkedin'))}
                type="url"
                variant="outlined"
                {...register('linkedin')}
                error={Boolean(errors.linkedin)}
                helperText={errors.linkedin?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('twitter'))}
                type="url"
                variant="outlined"
                {...register('twitter')}
                error={Boolean(errors.twitter)}
                helperText={errors.twitter?.message || ' '}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{
                margin: 1,
                position: 'relative',
              }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default ContactForm;
