import React from 'react';
import List from './list';
import Create from './create';
import Update from './update';
import Upload from './upload/Upload';

const routes: any = {
  path: 'roads',
  children: [
    { path: '', element: <List /> },
    { path: 'create', element: <Create /> },
    { path: 'upload', element: <Upload /> },
    { path: ':roadId/update', element: <Update /> },
  ],
};

export default routes;
