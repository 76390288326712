import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  RadioGroup,
  Radio,
  Checkbox,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Select from 'react-select';
import moment from 'moment';

const ScheduleForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any, create?: boolean }) => {
  const { onSubmit, loading, title, defaultValues, create } = props;
  const { t } = useTranslation();
  const schema = yup.object().shape({
    timeFrom: yup.object().required(t('isRequired')),
    timeTo: yup.object().required(t('isRequired')),
    dayOfTheWeek: create ?
      yup
        .array(
          yup
          .string()
          .oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
        )
        .required(t('isRequired')) :
      yup
        .string()
        .oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'])
        .required(t('isRequired')),
    specificDay: yup.string(),
    active: yup.boolean(),
  });

  let initialFormValues: any = { active: true };
  if (defaultValues) {
    const splitTimeRange = defaultValues.timeRange.split('..');
    const dateTimeFrom = moment(splitTimeRange[0]);
    const dateTimeTo = moment(splitTimeRange[1]);

    initialFormValues = {
      timeFrom: {
        label: dateTimeFrom.local().format('HH:mm'),
        value: dateTimeFrom.toISOString(),
      },
      timeTo: {
        label: dateTimeTo.local().format('HH:mm'),
        value: dateTimeTo.toISOString(),
      },
      active: defaultValues.active,
    };
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
  });

  const [timeOptions, setTimeOptions] = useState([]);
  useEffect(() => {
    const options = [];
    const today = moment().local();
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute <= 45; minute += 15) {
        const time = String(hour).padStart(2, '0') + ':' + String(minute).padStart(2, '0');
        today.set({ hour: hour, minute: minute, second: 0, millisecond: 0 });
        options.push({ label: time, value: today.toISOString() });
      }
    }

    setTimeOptions(options);
  }, []);
  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        dataToSubmit.timeRange = '[' + data.timeFrom.value + ', ' + data.timeTo.value + ']';
        delete dataToSubmit.timeFrom;
        delete dataToSubmit.timeTo;
        if (create) {
          const { dayOfTheWeek: daysOfTheWeek } = data;
          dataToSubmit.dayOfTheWeek = daysOfTheWeek[0];
          onSubmit(dataToSubmit, daysOfTheWeek);
        } else {
          onSubmit(dataToSubmit);
        }
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={2} direction="row" sx={{ width: '100%' }}>
            <Grid item md={6} xs={6} sx={{ width: '100%' }}>
              <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('from'))}</InputLabel>
              <Controller
                control={control}
                name="timeFrom"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Select
                      options={timeOptions}
                      filterOption={(option, searchText) => {
                        const formattedLabel = option.data.label.replace(':', '');
                        const formattedSearch = searchText.replace(':', '');
                        return formattedLabel.includes(formattedSearch);
                      }}
                      onChange={onChange}
                      value={value}
                      name={name}
                    />
                  );
                }}
              />
              {errors.timeFrom && (
                <InputLabel
                  sx={{
                    textAlign: 'left',
                    color: 'red',
                  }}>
                  {errors.timeFrom.message}
                </InputLabel>
              )}
            </Grid>
            <Grid item md={6} xs={6} sx={{ width: '100%' }}>
              <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('to'))}</InputLabel>
              <Controller
                control={control}
                name="timeTo"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Select
                      options={timeOptions}
                      filterOption={(option, searchText) => {
                        const formattedLabel = option.data.label.replace(':', '');
                        const formattedSearch = searchText.replace(':', '');
                        return formattedLabel.includes(formattedSearch);
                      }}
                      onChange={onChange}
                      value={value}
                      name={name}
                    />
                  );
                }}
              />
              {errors.timeTo && (
                <InputLabel
                  sx={{
                    textAlign: 'left',
                    color: 'red',
                  }}>
                  {errors.timeTo.message}
                </InputLabel>
              )}
            </Grid>
            <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
              <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('dayOfTheWeek'))}</InputLabel>
              <FormControl>
                <RadioGroup defaultValue={defaultValues?.dayOfTheWeek} {...register('dayOfTheWeek')}>
                  <FormControlLabel
                    value="monday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('monday'))}
                    {...register('dayOfTheWeek')}
                  />
                  <FormControlLabel
                    value="tuesday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('tuesday'))}
                    {...register('dayOfTheWeek')}
                  />
                  <FormControlLabel
                    value="wednesday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('wednesday'))}
                    {...register('dayOfTheWeek')}
                  />
                  <FormControlLabel
                    value="thursday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('thursday'))}
                    {...register('dayOfTheWeek')}
                  />
                  <FormControlLabel
                    value="friday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('friday'))}
                    {...register('dayOfTheWeek')}
                  />
                  <FormControlLabel
                    value="saturday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('saturday'))}
                    {...register('dayOfTheWeek')}
                  />
                  <FormControlLabel
                    value="sunday"
                    control={ create ? <Checkbox /> : <Radio />}
                    label={capitalize(t('sunday'))}
                    {...register('dayOfTheWeek')}
                  />
                </RadioGroup>
              </FormControl>
              {errors.dayOfTheWeek && (
                <InputLabel
                  sx={{
                    textAlign: 'left',
                    color: 'red',
                  }}>
                  {errors.dayOfTheWeek.message}
                </InputLabel>
              )}
            </Grid>
            <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Controller
                    name={'active'}
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                    )}
                  />
                }
                label={capitalize(t('active'))}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default ScheduleForm;
