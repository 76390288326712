import React, { useEffect } from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import {
  useGetContactNameLazyQuery,
  useGetFeatureNameLazyQuery,
  useGetLocationNameLazyQuery,
  useGetMapNameLazyQuery,
  useGetNotificationNameLazyQuery,
  useGetPinNameLazyQuery,
  useGetPlaceNameLazyQuery,
  useGetRoadNameLazyQuery,
  useGetRoleNameLazyQuery,
  useGetScheduleDayLazyQuery,
  useGetUserEmailLazyQuery,
  useGetLocationCategoryNameLazyQuery,
} from '../../../graphql';
import { capitalize } from 'lodash';
export default function AppBreadcrumbs() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoutes = location.pathname !== '/' ? location.pathname.split('/') : [];
  const [getContactName, { data: contactName }] = useGetContactNameLazyQuery();
  const [getFeatureName, { data: featureName }] = useGetFeatureNameLazyQuery();
  const [getLocationName, { data: locationName }] = useGetLocationNameLazyQuery();
  const [getMapName, { data: mapName }] = useGetMapNameLazyQuery();
  const [getNotificationName, { data: notificationName }] = useGetNotificationNameLazyQuery();
  const [getPinName, { data: pinName }] = useGetPinNameLazyQuery();
  const [getPlaceName, { data: placeName }] = useGetPlaceNameLazyQuery();
  const [getRoadName, { data: roadName }] = useGetRoadNameLazyQuery();
  const [getRoleName, { data: roleName }] = useGetRoleNameLazyQuery();
  const [getScheduleDay, { data: scheduleDay }] = useGetScheduleDayLazyQuery();
  const [getUserEmail, { data: userEmail }] = useGetUserEmailLazyQuery();
  const [getLocationCategoryName, { data: locationCategoryName }] = useGetLocationCategoryNameLazyQuery();


  const currentNameDict: any = {
    contacts: {
      get: getContactName,
      name: contactName,
    },
    features: {
      get: getFeatureName,
      name: featureName,
    },
    locations: {
      get: getLocationName,
      name: locationName,
    },
    maps: {
      get: getMapName,
      name: mapName,
    },
    notifications: {
      get: getNotificationName,
      name: notificationName,
    },
    pins: {
      get: getPinName,
      name: pinName,
    },
    places: {
      get: getPlaceName,
      name: placeName,
    },
    roads: {
      get: getRoadName,
      name: roadName,
    },
    roles: {
      get: getRoleName,
      name: roleName,
    },
    schedules: {
      get: getScheduleDay,
      name: scheduleDay,
    },
    users: {
      get: getUserEmail,
      name: userEmail,
      id: 'uid',
    },
    categories: {
      get: getLocationCategoryName,
      name: locationCategoryName,
    }
  };

  const keywords = [...Object.keys(currentNameDict), 'update', 'new'];
  useEffect(() => {
    currentRoutes.forEach((route, index) => {
      if (keywords.indexOf(route) < 0) {
        const previousContext: string = currentRoutes[index - 1];
        currentNameDict[previousContext]?.get({ variables: { [currentNameDict[previousContext].id || 'id']: route } });
      }
    });
  }, [location.pathname]);

  const getCurrentName = (index: number, currentRoutes: string[]) => {
    const previousContext: string = currentRoutes[index - 1];
    const nameResponse = currentNameDict[previousContext].name;
    if (nameResponse) {
      let key = 'name';
      if (previousContext === 'schedules') return t(nameResponse[previousContext][0].dayOfTheWeek);
      if (previousContext === 'contacts') key = 'displayName';
      if (previousContext === 'users') key = 'email';
      return nameResponse[previousContext]?.[0]?.[key];
    }
    return '...';
  };

  if (currentRoutes.length > 0) currentRoutes.shift();
  if (currentRoutes.length > 0) {
    return (
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: 15 }} separator={<NavigateNextIcon fontSize="small" />}>
        {currentRoutes.map((route, index) => {
          return index !== currentRoutes.length - 1 ? (
            keywords.indexOf(route) >= 0 ? (
              <Link
                key={index}
                color="inherit"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(`${location.pathname.split(route)[0]}${route}`);
                }}>
                {capitalize(t(route))}
              </Link>
            ) : (
              <Typography key={index} color="textPrimary">
                {getCurrentName(index, currentRoutes)}
              </Typography>
            )
          ) : route !== 'login' ? (
            <Typography key={index} color="textPrimary">
              {capitalize(t(route))}
            </Typography>
          ) : null;
        })}
      </Breadcrumbs>
    );
  } else return <></>;
}
