export default {
  contact: 'contact',
  contacts: 'contacts',
  contactsDescription: 'Add all possible contacts that you think are necessary to associate them as general contact information or as point of interest information.',
  email: 'email',
  contactNumbers: 'contact numbers',
  phone: 'phone',
  mobile: 'mobile',
  whatsapp: 'WhatsApp',
  web: 'website',
  socialMedias: 'social medias',
  displayName: 'visible name on "Created by:"',
  createdBy: 'created by',
  contactInfo: 'contact info',
};
