import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from '../components';
import {
  useCreateRoadMutation,
  CreateRoadMutationVariables,
  useGetMapQuery,
  useUpdateRoadNodesMutation,
} from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId, mapId } = useParams();
  const [createRoad, { data: createData, loading: createLoading }] = useCreateRoadMutation();
  const [updateRoadNodes, { data: updateRoadNodesData }] = useUpdateRoadNodesMutation();

  const { loading, data } = useGetMapQuery({ variables: { id: mapId.toString() } });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [nodesToUpdate, setNodesToUpdate] = useState<any[]>(null);
  const [testIntermediateNodes, setTestIntermediateNodes] = useState<boolean>(true);

  useEffect(() => {
    if (createData && nodesToUpdate.length > 0) {
      const roadId = createData.createRoad.road.id;
      updateRoadNodes({ variables: { nodes: nodesToUpdate, roadId, unidirectional: false, testIntermediateNodes } });
    } else if (createData) {
      navigate(`/places/${placeId}/maps/${mapId}/roads`);
    }
  }, [createData]);

  useEffect(() => {
    if (updateRoadNodesData) {
      navigate(`/places/${placeId}/maps/${mapId}/roads`);
    }
  }, [updateRoadNodesData]);

  const onSubmit = (dataToSubmit: CreateRoadMutationVariables & { nodes: any[] }, testIntermediateNodes: boolean ) => {
    createRoad({ variables: { ...dataToSubmit, mapId } });
    setTestIntermediateNodes(testIntermediateNodes)
    if (dataToSubmit.nodes) {
      setNodesToUpdate(dataToSubmit.nodes?.map((node) => [node.lat, node.lng]));
    }
  };
  if (loading) {
    return <></>;
  }
  return (
    <>
      <Form
        onSubmit={onSubmit}
        loading={createLoading}
        title={t('createTitle', { field: t('road') })}
        map={data?.maps[0]}
      />
    </>
  );
};
export default Create;
