import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, colors, Typography, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Grid, TextField, Stack, Box, Divider } from '@mui/material';
import { SketchPicker } from 'react-color';
import ImageDropzone from '../../../common/components/ImageDropzone/ImageDropzone';
import InfographicsItemTexts from './InfographicsItemTexts';

const InfographicsItem = (props: {
  infographicsItem: any;
  setInfographicsItem: any;
  deleteInfographicsItem: any;
  defaultExpanded: boolean;
  backgroundColor: string;
  moveInfographicsItem: any;
  moveUpEnabled: boolean;
  moveDownEnabled: boolean;
}) => {
  const {
    infographicsItem,
    setInfographicsItem,
    deleteInfographicsItem,
    defaultExpanded,
    backgroundColor,
    moveInfographicsItem,
    moveUpEnabled,
    moveDownEnabled,
  } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(0);

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        sx={{
          '& .MuiAccordionSummary-content': {
            justifyContent: 'flex-end',
          },
          backgroundColor,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography sx={{ marginRight: 'auto' }}>{infographicsItem.title || 'Infography'}</Typography>
        <Tooltip title={t('Move down')}>
          <IconButton
            sx={{
              color: colors.grey[900],
            }}
            disabled={!moveDownEnabled}
            aria-label="move-down"
            onClick={(event) => {
              event.stopPropagation();
              moveInfographicsItem(1);
            }}>
            <ArrowDownwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Move up')}>
          <IconButton
            sx={{
              color: colors.grey[900],
            }}
            disabled={!moveUpEnabled}
            aria-label="move-up"
            onClick={(event) => {
              event.stopPropagation();
              moveInfographicsItem(-1);
            }}>
            <ArrowUpwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Delete')}>
          <IconButton
            sx={{
              color: colors.red[600],
            }}
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              deleteInfographicsItem();
            }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </AccordionSummary>
      <Paper elevation={8}>
        <AccordionDetails>
          <Stack
            direction="row"
            spacing={6}
            style={{ width: '100%' }}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Box width="50%">
              <Grid container spacing={1} direction="column">
                <Grid item md={12} xs={12}>
                  <TextField
                    onChange={(e) => setInfographicsItem({ ...infographicsItem, title: e.target.value })}
                    value={infographicsItem.title}
                    fullWidth
                    label={'Title'}
                    name="title"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <>
                    <TextField
                      fullWidth
                      label={t('color')}
                      name="color"
                      type="text"
                      variant="outlined"
                      value={infographicsItem.backgroundColor}
                      onClick={() => setOpen(true)}
                    />
                    <Dialog onClose={() => setOpen(false)} open={open}>
                      <SketchPicker
                        color={infographicsItem.backgroundColor}
                        onChange={(e: any) => setInfographicsItem({ ...infographicsItem, backgroundColor: e.hex })}
                      />
                    </Dialog>
                  </>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    onChange={(e) =>
                      setInfographicsItem({
                        ...infographicsItem,
                        image: {
                          ...infographicsItem.image,
                          initialHeight: Number(e.target.value),
                          finalHeight: Number(e.target.value),
                        },
                      })
                    }
                    value={infographicsItem?.image?.initialHeight}
                    fullWidth
                    label={'initialHeight'}
                    name="initialHeight"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    onChange={(e) =>
                      setInfographicsItem({
                        ...infographicsItem,
                        image: { ...infographicsItem.image, finalHeight: Number(e.target.value) },
                      })
                    }
                    value={infographicsItem?.image?.finalHeight}
                    fullWidth
                    label={'finalHeight'}
                    name="finalHeight"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <InfographicsItemTexts
                    texts={infographicsItem.texts}
                    setTexts={(texts: any) =>
                      setInfographicsItem({
                        ...infographicsItem,
                        texts,
                      })
                    }
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <ImageDropzone
                    setFiles={(files) =>
                      setInfographicsItem({ ...infographicsItem, image: { ...infographicsItem.image, url: files[0] } })
                    }
                    files={infographicsItem?.image?.url ? [infographicsItem?.image?.url] : []}
                    allowedFileTypes={[]}
                    resize={{
                      maxWidth: 1980,
                      maxHeight: 1980,
                      format: 'PNG',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box width="50%">
              Preview
              <Box
                style={{ marginTop: '250px', height: '600px', overflowY: 'scroll' }}
                onScroll={(e: any) => setCurrentScroll(e.currentTarget.scrollTop / 200)}
              >
                <Box height="800px">
                  <Box
                    style={{
                      backgroundColor: infographicsItem.backgroundColor || 'white',
                      width:'350px',
                      lineHeight:'400px',
                      margin:'auto',
                      position: 'relative',
                    }}>
                    <img
                      style={{
                        verticalAlign: 'middle',
                        height: (infographicsItem?.image?.initialHeight || 0)*(1-currentScroll)
                          + (infographicsItem?.image?.finalHeight || 0)*currentScroll,
                        maxHeight: (infographicsItem?.image?.initialHeight * 2),
                        overflow: 'hidden',
                      }}
                      src={infographicsItem?.image?.url || ''}
                    />
                    {infographicsItem.texts?.map((e: any) => (
                      <Box
                        key={e.content}
                        style={{
                          position: 'absolute',
                          lineHeight: 'initial',
                          borderRadius: '5px',
                          left: `${(e.initialPosition[0]*100*(1-currentScroll)) + (e.finalPosition[0]*100*currentScroll)}%`,
                          top: `${(e.initialPosition[1]*100*(1-currentScroll)) + (e.finalPosition[1]*100*currentScroll)}%`,
                          width: `${e.width}px`,
                          fontSize: e.fontSize,
                          color: e.textColor,
                          backgroundColor: e.backgroundColor,
                        }}
                      >
                        {e.content}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>

        </AccordionDetails>
      </Paper>
    </Accordion>
  );
};
export default InfographicsItem;
