import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Stack, useMediaQuery } from '@mui/material';
import { Scrollbar } from '../components/scrollbar';
import { SideNavItem } from './side-nav-item';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { selectedPlace } from '../../cache';
import SelectedPlace from '../components/SelectedPlace';

import MapIcon from '@mui/icons-material/Map';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import PinDropIcon from '@mui/icons-material/PinDrop';
import RoomIcon from '@mui/icons-material/Room';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PreviewIcon from '@mui/icons-material/Preview';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PlaceIcon from '@mui/icons-material/Place';
import { checkPermission } from '../../utils/check-permission';
import CategoryIcon from '@mui/icons-material/Category';

export const SideNav = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const location = useLocation();
  const { t } = useTranslation();
  const selectedPlaceId = useReactiveVar(selectedPlace);
  const canUpdatePins = checkPermission('create_pins_all');
  const canUpdateNotifications = checkPermission('create_notifications_all');
  // need to send null to check generic permissions
  const canSeeUsers = checkPermission('read_users_all', null);
  const canSeeRoles = checkPermission('read_roles_all', null)

  const selectedPlacePages = [
    {
      title: capitalize(t('view')),
      path: `/places/${selectedPlaceId}/view`,
      icon: <PreviewIcon />,
    },
    {
      title: capitalize(t('update')),
      path: `/places/${selectedPlaceId}/update`,
      icon: <EditIcon />,
    },
    {
      title: capitalize(t('maps')),
      path: `/places/${selectedPlaceId}/maps`,
      icon: <MapIcon />,
    },
    {
      title: capitalize(t('locations')),
      path: `/places/${selectedPlaceId}/locations`,
      icon: <PinDropIcon />,
    },
    {
      title: capitalize(t('categories')),
      path: `/places/${selectedPlaceId}/categories`,
      icon: <CategoryIcon />,
    },
    {
      title: capitalize(t('pins')),
      path: `/places/${selectedPlaceId}/pins`,
      icon: <RoomIcon />,
      disabled: !canUpdatePins,
    },
    {
      title: capitalize(t('notifications')),
      path: `/places/${selectedPlaceId}/notifications`,
      icon: <NotificationsActiveIcon />,
      disabled: !canUpdateNotifications,
    },
    {
      title: capitalize(t('contacts')),
      path: `/places/${selectedPlaceId}/contacts`,
      icon: <ContactMailIcon />
    },
    {
      title: capitalize(t('roles')),
      path: `/places/${selectedPlaceId}/roles`,
      icon: <PeopleIcon />,
      disabled: !canSeeRoles
    },
  ];

  const generalPages = [
    {
      title: capitalize(t('places')),
      path: '/places',
      icon: <PlaceIcon />,
    },
  ];
  if (canSeeUsers) {
    generalPages.push({
      title: capitalize(t('users')),
      path: '/users',
      icon: <PersonIcon />,
    })
    generalPages.push({
      title: 'GraphiQL',
      path: '/graphiql',
      icon: <QueryStatsIcon />,
    })
  }

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
        '& .simplebar-scrollbar:before': {
          background: 'neutral.400',
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <SelectedPlace />
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}>
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0,
            }}>
            {selectedPlacePages.map((item) => {
              const active = location.pathname === item.path;

              return (
                <SideNavItem active={active} icon={item.icon} key={item.title} path={item.path} title={item.title} disabled={item.disabled} />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}>
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0,
            }}>
            {generalPages.map((item) => {
              const active = location.pathname === item.path;

              return (
                <SideNavItem active={active} icon={item.icon} key={item.title} path={item.path} title={item.title} disable={item.disabled} />
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.800',
            color: 'common.white',
            width: 280,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.800',
          color: 'common.white',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary">
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
