import React, { useEffect } from 'react';
import { Feature } from '../../../graphql';
import DynamicList from '../../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDeleteFeatureMutation, useGetFeaturesQuery, refetchGetFeaturesQuery } from '../../../graphql';

const FeatureList = () => {
  const { placeId, locationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { loading, data } = useGetFeaturesQuery({ variables: { locationId } });
  const [deleteFeature, { loading: deleteLoading }] = useDeleteFeatureMutation({
    refetchQueries: [refetchGetFeaturesQuery({ locationId })],
    awaitRefetchQueries: true,
  });

  const dict: any = {
    0: 'Baja',
    1: 'Media Baja',
    2: 'Media',
    3: 'Media Alta',
    4: 'Alta',
  };

  const [selected, setSelected] = React.useState<Feature>(null);
  useEffect(() => {
    if (selected) {
      Swal.fire({
        title: t('Are you sure?'),
        // eslint-disable-next-line quotes
        text: t(`cantRevertAction`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
      }).then((result) => {
        if (result.value) {
          Swal.showLoading();
          deleteFeature({ variables: { id: selected.id } });
        }
        setSelected(null);
      });
    }
  }, [selected, deleteFeature, t]);

  return (
    <div>
      <DynamicList
        loading={loading}
        description={t('featuresDescription')}
        actions={
          <Button
            component={Link}
            to={`/places/${placeId}/locations/${locationId}/features/create`}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddCircleIcon />}>
            {t('create')}
          </Button>
        }
        objectList={data?.features}
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => e.name,
          },
          {
            label: t('map'),
            renderFn: (e: any) => e.map.name,
          },
          {
            label: t('toZoom'),
            renderFn: (e: any) => (!e.toZoom || e.toZoom >= e.map?.maxZoom)
              ? 'Sin limite'
              : dict[(e.map?.maxZoom - (e.toZoom || 21)) / ((e.map?.maxZoom - e.map?.minZoom) / (Object.keys(dict).length - 1))],
          },
          {
            label: t('fromZoom'),
            renderFn: (e: any) => (!e.fromZoom || e.fromZoom <= e.map?.minZoom)
              ? 'Sin limite'
              : dict[(e.map?.maxZoom - (e.fromZoom || 0)) / ((e.map?.maxZoom - e.map?.minZoom) / (Object.keys(dict).length - 1))],
          },
          {
            label: t('floor'),
            renderFn: (e: any) => e.map.floor,
          },
          {
            label: t('createdAt'),
            renderFn: (e: any) => moment(e.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: t('updatedAt'),
            renderFn: (e: any) => moment(e.updatedAt).format('DD/MM/YYYY'),
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => navigate(`/places/${placeId}/locations/${locationId}/features/${e.id}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton sx={{ color: colors.red[600] }} onClick={() => setSelected(e)}>
                    {deleteLoading ? <CircularProgress /> : <DeleteIcon />}
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default FeatureList;
