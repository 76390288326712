import React, { useEffect } from 'react';
import DynamicList from '../../../common/components/DynamicList';
import { useTranslation } from 'react-i18next';
import { IconButton, colors, ButtonGroup, Tooltip, Button, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TikTokIcon from '../../../assets/images/tiktok-svgrepo-com.svg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Contact, useGetContactsQuery, refetchGetContactsQuery, useDeleteContactMutation } from '../../../graphql';

const ContactList = () => {
  const { placeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { loading, data } = useGetContactsQuery({ variables: { placeId } });

  const [deleteContact, { loading: deleteLoading }] = useDeleteContactMutation({
    refetchQueries: [refetchGetContactsQuery({ placeId })],
    awaitRefetchQueries: true,
  });
  const [selected, setSelected] = React.useState<Contact>(null);
  useEffect(() => {
    if (selected) {
      Swal.fire({
        title: t('Are you sure?'),
        // eslint-disable-next-line quotes
        text: t(`cantRevertAction`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
      }).then((result) => {
        if (result.value) {
          Swal.showLoading();
          deleteContact({ variables: { id: selected.id } });
        }
        setSelected(null);
      });
    }
  }, [selected, deleteContact, t]);

  return (
    <div>
      <DynamicList
        loading={loading}
        description={t('contactsDescription')}
        actions={
          <Button
            component={Link}
            to={`/places/${placeId}/contacts/create`}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddCircleIcon />}>
            {t('create')}
          </Button>
        }
        objectList={data?.contacts}
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => t(e.displayName),
          },
          {
            label: t('email'),
            renderFn: (e: any) => t(e.email),
          },
          {
            label: t('contactNumbers'),
            renderFn: (e: any) => {
              const { phone, mobile, whatsapp } = e;
              return (
                <Box>
                  {phone && (
                    <Tooltip title={phone}>
                      <IconButton href={`tel:${phone}`} target="_blank" sx={{ color: colors.grey[900] }}>
                        <LocalPhoneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {mobile && (
                    <Tooltip title={mobile}>
                      <IconButton href={`tel:${mobile}`} target="_blank" sx={{ color: colors.grey[600] }}>
                        <AddToHomeScreenIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {whatsapp && (
                    <Tooltip title={whatsapp}>
                      <IconButton href={`https://web.whatsapp.com/send?phone=${whatsapp.replace(' ', '')}`} target="_blank" sx={{ color: colors.green[500] }}>
                        <WhatsAppIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
          {
            label: t('socialMedias'),
            renderFn: (e: any) => {
              const { web, facebook, instagram, tikTok, linkedin, twitter } = e;
              return (
                <Box>
                  {web && (
                    <Tooltip title={web}>
                      <IconButton href={web} target="_blank" sx={{ color: colors.lightBlue[500] }}>
                        <LanguageIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {facebook && (
                    <Tooltip title={facebook}>
                      <IconButton href={facebook} target="_blank" sx={{ color: colors.blue[500] }}>
                        <FacebookIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {instagram && (
                    <Tooltip title={instagram}>
                      <IconButton href={instagram} target="_blank" sx={{ color: colors.red[500] }}>
                        <InstagramIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {tikTok && (
                    <Tooltip title={tikTok}>
                      <IconButton href={tikTok} target="_blank">
                        <img alt="Logo" src={TikTokIcon} style={{ width: '1em' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {linkedin && (
                    <Tooltip title={linkedin}>
                      <IconButton href={linkedin} target="_blank" sx={{ color: colors.blue[700] }}>
                        <LinkedInIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {twitter && (
                    <Tooltip title={twitter}>
                      <IconButton href={twitter} target="_blank" sx={{ color: colors.lightBlue[500] }}>
                        <TwitterIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => navigate(`/places/${placeId}/contacts/${e.id}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton sx={{ color: colors.red[600] }} onClick={() => setSelected(e)}>
                    {deleteLoading ? <CircularProgress /> : <DeleteIcon />}
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ContactList;
