import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Tooltip, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useLeafletContext } from '@react-leaflet/core';
import { useParams } from 'react-router-dom';
import { MapModal } from '../../../common/components';

// @Todo add map typing, its bugged because of point and pointGeo inconsistencies in api schema
const NodesEdit = (props: { map: any; road: any; onChange: any }) => {
  const { map, road, onChange } = props;
  const { roadId } = useParams();
  const canDraw = road.nodes?.length === 0;
  const bounds: any =
    map.pointNe && map.pointSw
      ? [map.pointNe, map.pointSw]
      : [
          { lat: map.pointNeGeo.latitude, lng: map.pointNeGeo.longitude },
          { lat: map.pointSwGeo.latitude, lng: map.pointSwGeo.longitude },
        ];

  const Toolbar = (): any => {
    const context = useLeafletContext();

    useEffect(() => {
      context.map.pm.addControls({
        position: 'topleft',
        drawControls: canDraw,
        drawCircle: false,
        drawCircleMarker: false,
        drawMarker: false,
        drawPolygon: false,
        drawRectangle: false,
        drawText: false,
      });
      context.map.on('pm:create', (e) => {
        const layer = e.layer as any;
        onChange(layer.getLatLngs());
        e.layer.remove();
      });
      context.map.on('pm:remove', () => {
        onChange([]);
      });
    });

    return <></>;
  };
  return (
    <MapModal
      bounds={bounds}
      title={'Seleccionar nodos del camino'}
      customTile={map?.tileUrl}
    >
      {map?.roads?.map((previewRoad: any) => {
        if (previewRoad.id === roadId || previewRoad.nodes?.length <= 0) {
          return (
            <Polyline
              pmIgnore={true}
              key={previewRoad.id}
              // NOTE: its added 3 of width only for a easy show of tooltips
              pathOptions={{ color: 'black', weight: 1 }}
              positions={[previewRoad.nodes?.map((node: any) => [node.pointGeo.latitude, node.pointGeo.longitude])]}
            >
              <Tooltip direction="bottom" opacity={1} sticky>
                {previewRoad.name} (inicial)
              </Tooltip>
            </Polyline>
          );
        }
        return (
          <Polyline
            pmIgnore={true}
            key={previewRoad.id}
            // NOTE: its added 3 of width only for a easy show of tooltips
            pathOptions={{ color: previewRoad.color, weight: previewRoad.width + 3 }}
            positions={[previewRoad.nodes?.map((node: any) => [node.pointGeo.latitude, node.pointGeo.longitude])]}
          >
            <Tooltip direction="bottom" opacity={1} sticky>
              {previewRoad.name}
            </Tooltip>
          </Polyline>
        );
      })}
      {road.nodes?.length > 1 && (
        <Polyline
          pmIgnore={false}
          key={road?.id}
          pathOptions={{ color: road.color || '#755c42', weight: (road.width || 3) + 3 }}
          positions={road?.nodes?.map((node: any) => [node?.lat, node?.lng])}
          eventHandlers={{
            add: (e) => {
              e.target.on('pm:edit', (x: any) => {
                onChange(x.layer.getLatLngs());
              });
            },
          }}
        />
      )}
      <Toolbar />
    </MapModal>
  );
};
export default NodesEdit;
