import { ApolloClient, InMemoryCache, from, HttpLink, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { gqlErrors, accessToken, refreshToken } from './cache';
import { RefreshTokenDocument } from './graphql';

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback());
  pendingRequests = [];
};

const getNewToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return client.mutate({ mutation: RefreshTokenDocument, variables: { refreshToken } }).then((result) => {
    return {
      accessTokenValue: result.data?.refreshToken?.refreshToken?.idToken,
      refreshTokenValue: result.data?.refreshToken?.refreshToken?.refreshToken,
    };
  });
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const errors: string[] = [];

  if (graphQLErrors)
    for (const { message, locations, path } of graphQLErrors) {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      errors.push(message);
      if (message === 'INVALID_ID_TOKEN') {
        let forward$;
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewToken()
              .then(({ accessTokenValue, refreshTokenValue }: any) => {
                // Store the new tokens for your auth link
                localStorage.setItem('accessToken', accessTokenValue);
                accessToken(accessTokenValue);
                localStorage.setItem('refreshToken', refreshTokenValue);
                refreshToken(refreshTokenValue);
                const oldHeaders = operation.getContext().headers;
                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessTokenValue}`,
                  },
                });
                resolvePendingRequests();
                return accessTokenValue;
              })
              .catch(() => {
                pendingRequests = [];
                localStorage.setItem('accessToken', '');
                accessToken('');
                localStorage.setItem('refreshToken', '');
                refreshToken('');
                return;
              })
              .finally(() => {
                isRefreshing = false;
              }),
          ).filter((value) => Boolean(value));
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise<void>((resolve) => {
              pendingRequests.push(() => resolve());
            }),
          );
        }

        return forward$.flatMap(() => forward(operation));
      }
    }
  gqlErrors(errors);
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// Catch all requests
/* const errorOnDataLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    return response;
  });
}); */

const apiLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return { headers };
  }
});

const link = from([errorLink, authLink, apiLink]);

const client = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: link,
  cache: new InMemoryCache(),
  // cache: new InMemoryCache({
  //   typePolicies: {
  //     Query: {
  //       fields: {
  //         // diagnostic query use the cache
  //         study: {
  //           read(_, { args, toReference }) {
  //             return toReference({
  //               __typename: 'Study',
  //               id: args.id,
  //             });
  //           },
  //         },
  //       },
  //     },
  //   },
  // }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default client;
