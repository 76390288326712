import React from 'react';
import FeatureCreator from './FeatureCreator';
import List from './List';
import Update from '../features/update';
const routes: any = {
  path: 'features',
  children: [
    { path: '', element: <List /> },
    { path: 'create', element: <FeatureCreator /> },
    { path: ':featureId/update', element: <Update /> },
  ],
};
export default routes;
