export default {
  schedulesDescription: 'Los horarios son utilizados para mostrar a los visitantes la hora de entrada y salida de cada dia en el punto de interés (en caso de que existan), debe crearse uno por cada dia de la semana en que se permite el ingreso',
  schedule: 'horario',
  schedules: 'horarios',
  dayOfTheWeek: 'dia de la semana',
  specificDay: 'dia especifico',
  active: 'activo',
  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábado',
  sunday: 'domingo',
};
