import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPlaceQuery } from '../../../graphql';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MapIcon from '@mui/icons-material/Map';
import EditIcon from '@mui/icons-material/Edit';
import PinDropIcon from '@mui/icons-material/PinDrop';
import RoomIcon from '@mui/icons-material/Room';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import { colors } from '@mui/material';

import { capitalize } from 'lodash';
import { Tooltip } from '@mui/material';
import { checkPermission } from '../../../utils/check-permission';

const View = () => {
  const { placeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, data } = useGetPlaceQuery({ variables: { id: placeId.toString() } });

  const canUpdatePins = checkPermission('create_pins_all');
  const canUpdateNotifications = checkPermission('create_notifications_all');
  const canSeeRoles = checkPermission('read_roles_all')

  if (loading) {
    return <></>;
  }

  const place = data.places[0];



  return (
    <>
      <Card sx={{ margin: '5em', overflow: 'inherit' }}>
        <CardHeader title={place.name} />
        <CardMedia component="img" image={place.logoUrl} alt="Logo url" sx={{ maxWidth: '30rem', margin: 'auto' }} />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {place.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Tooltip title={capitalize(t('update'))}>
            <IconButton
              sx={{
                color: colors.yellow[800],
              }}
              onClick={() => navigate(`/places/${placeId}/update`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={capitalize(t('maps'))}>
            <IconButton
              sx={{
                color: colors.blue[700],
              }}
              onClick={() => navigate(`/places/${placeId}/maps`)}>
              <MapIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={capitalize(t('locations'))}>
            <IconButton
              sx={{
                color: colors.blue[700],
              }}
              onClick={() => navigate(`/places/${placeId}/locations`)}>
              <PinDropIcon />
            </IconButton>
          </Tooltip>

          {canUpdatePins && <Tooltip title={capitalize(t('pins'))}>
            <IconButton
              sx={{
                color: colors.blue[700],
              }}
              onClick={() => navigate(`/places/${placeId}/pins`)}>
              <RoomIcon />
            </IconButton>
          </Tooltip>}

          {canUpdateNotifications && <Tooltip title={capitalize(t('notifications'))}>
            <IconButton
              sx={{
                color: colors.blue[700],
              }}
              onClick={() => navigate(`/places/${placeId}/notifications`)}>
              <NotificationsActiveIcon />
            </IconButton>
          </Tooltip>}

          {canSeeRoles && <Tooltip title={capitalize(t('roles'))}>
            <IconButton
              sx={{
                color: colors.blue[700],
              }}
              onClick={() => navigate(`/places/${placeId}/roles`)}>
              <PeopleIcon />
            </IconButton>
          </Tooltip>}
        </CardActions>
      </Card>
    </>
  );
};
export default View;
