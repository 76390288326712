import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from '../components';
import {
  useUpdateRoadMutation,
  useGetMapQuery,
  UpdateRoadMutationVariables,
  useUpdateRoadNodesMutation,
} from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { roadId, placeId, mapId } = useParams();
  const [updateRoad, { loading: updateLoading }] = useUpdateRoadMutation();
  const [updateRoadNodes, { loading: updateNodesLoading }] = useUpdateRoadNodesMutation();

  const { loading, data } = useGetMapQuery({ variables: { id: mapId.toString() } });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (
    dataToSubmit: UpdateRoadMutationVariables & { nodes: any[] },
    testIntermediateNodes: boolean,
  ) => {
    const { unidirectional, nodes } = dataToSubmit;

    updateRoad({ variables: { ...dataToSubmit, id: roadId } });
    updateRoadNodes({
      variables: {
        nodes: nodes?.map((node) => [node.lat, node.lng]),
        roadId,
        unidirectional,
        testIntermediateNodes
      },
      onCompleted: () => {
        navigate(`/places/${placeId}/maps/${mapId}/roads`);
      },
    });
  };
  if (loading) {
    return <></>;
  }
  return (
    <>
      <Form
        onSubmit={onSubmit}
        loading={updateLoading && updateNodesLoading}
        title={t('updateTitle', { field: t('road') })}
        defaultValues={data?.maps[0].roads.find((road) => road.id == roadId)}
        map={data?.maps[0]}
      />
    </>
  );
};
export default Update;
