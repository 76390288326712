import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Polyline, Polygon, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useLeafletContext } from '@react-leaflet/core';
import { MapModal } from '../../../common/components';

// @Todo unify features/components/nodesEdit and roads/components/nodesEdit in a single component for reusability
// @Todo add map typing, its bugged because of point and pointGeo inconsistencies in api schema
const NodesEdit = (props: { map: any; onChange: any; nodes: any[]; marker: any }) => {
  const { map, onChange, nodes, marker } = props;
  const canDraw = nodes?.length === 0;

  const bounds: any =
    map.pointNe && map.pointSw
      ? [map.pointNe, map.pointSw]
      : [
          { lat: map.pointNeGeo.latitude, lng: map.pointNeGeo.longitude },
          { lat: map.pointSwGeo.latitude, lng: map.pointSwGeo.longitude },
        ];

  const Toolbar = (): any => {
    const context = useLeafletContext();

    useEffect(() => {
      context.map.pm.addControls({
        position: 'topleft',
        drawControls: canDraw,
        drawCircle: false,
        drawCircleMarker: false,
        drawMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawText: false,
      });
      context.map.on('pm:create', (e) => {
        const layer = e.layer as any;
        // when creating a Polyline it returns an array of points
        // when creating a Polygon it returns an array of polygons
        onChange(layer.getLatLngs()[0]);
        e.layer.remove();
      });
      context.map.on('pm:remove', () => {
        onChange([]);
      });
    });

    return <></>;
  };

  return (
    <MapModal
      bounds={bounds}
      title={'Seleccionar contorno del pin'}
      customTile={map?.tileUrl}
    >
      {nodes.length > 1 && (
        <Polygon
          pmIgnore={false}
          key={map?.id}
          pathOptions={{}}
          positions={nodes.map((node: any) => [node?.lat, node?.lng])}
          eventHandlers={{
            add: (e) => {
              e.target.on('pm:edit', (x: any) => {
                onChange(x.layer.getLatLngs()[0]);
              });
            },
          }}
        />
      )}
      {map?.roads?.map((previewRoad: any) => {
        return (
          <Polyline
            pmIgnore={true}
            key={previewRoad.id}
            pathOptions={{ color: previewRoad.color, weight: previewRoad.width }}
            positions={[previewRoad.nodes?.map((node: any) => [node.pointGeo.latitude, node.pointGeo.longitude])]}
          />
        );
      })}
      {marker && <Marker position={marker} />}
      <Toolbar />
    </MapModal>
  );
};
export default NodesEdit;
