import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlaceForm } from '../components';
import { useUpdatePlaceMutation, useGetPlaceQuery, UpdatePlaceMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { placeId } = useParams();
  const [updatePlace, { data: updateData, loading: updateLoading }] = useUpdatePlaceMutation();
  const { t } = useTranslation();
  const { loading, data } = useGetPlaceQuery({ variables: { id: placeId.toString() } });
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      navigate('/places');
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdatePlaceMutationVariables) => {
    updatePlace({ variables: { ...dataToSubmit, id: placeId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <PlaceForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('place') })}
        defaultValues={data?.places[0]}
      />
    </>
  );
};
export default Update;
