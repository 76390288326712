import React, { useEffect } from 'react';
import { Road } from '../../../graphql';
import DynamicList from '../../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDeleteRoadMutation, useGetRoadsQuery, refetchGetRoadsQuery } from '../../../graphql';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const RoadList = () => {
  const { placeId, mapId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, data } = useGetRoadsQuery({ variables: { id: mapId } });
  const [deleteRoad, { loading: deleteLoading }] = useDeleteRoadMutation({
    refetchQueries: [refetchGetRoadsQuery({ id: mapId })],
    awaitRefetchQueries: true,
  });
  const [selected, setSelected] = React.useState<Road>(null);
  useEffect(() => {
    if (selected) {
      Swal.fire({
        title: t('Are you sure?'),
        text: t('cantRevertAction'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
      }).then((result) => {
        if (result.value) {
          Swal.showLoading();
          deleteRoad({ variables: { id: selected.id } });
        }
        setSelected(null);
      });
    }
  }, [selected, deleteRoad, t]);

  return (
    <div>
      <DynamicList
        loading={loading}
        description={t('roadsDescription')}
        actions={
          <>
            <Button
              component={Link}
              to={`/places/${placeId}/maps/${mapId}/roads/create`}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddCircleIcon />}>
              {t('create')}
            </Button>
            <Button
              component={Link}
              to={`/places/${placeId}/maps/${mapId}/roads/upload`}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<UploadFileIcon />}>
              {t('uploadRoads')}
            </Button>
          </>
        }
        objectList={data?.maps[0]?.roads}
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => e.name,
          },
          {
            label: t('nodes'),
            renderFn: (e: any) => e.nodes.length,
          },
          {
            label: `${t('distance')} [m]`,
            renderFn: (e: any) => Math.round(e.distance || 0),
          },
          {
            label: t('visible'),
            renderFn: (e: any) => e.visible ? 'Si' : 'No',
          },
          {
            label: t('color'),
            renderFn: (e: any) => e.color,
          },
          {
            label: t('width'),
            renderFn: (e: any) => e.width,
          },
          {
            label: t('unidirectional'),
            renderFn: (e: any) => e.unidirectional ? 'Si' : 'No',
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => navigate(`/places/${placeId}/maps/${mapId}/roads/${e.id}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton sx={{ color: colors.red[600] }} onClick={() => setSelected(e)}>
                    {deleteLoading ? <CircularProgress /> : <DeleteIcon />}
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default RoadList;
