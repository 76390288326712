export default {
  locationsDescription:
    'The points of interest represent a specific entity of the place, which can have one or multiple locations, be it an activity, attraction, restrooms, etc.',
  location: 'location',
  locations: 'locations',
  canReview: 'can be rated',
  logoUrl: 'Logo',
  testIntermediateNodes:
    'Search for intermediate relationships in the path (this can slow down the creation/editing of the path a lot, but it automatically creates the relationships with other paths that pass through it, it is recommended that when this parameter is active, it is a path with few nodes)',
  dynamicDescription: 'Dynamic Description',
};
