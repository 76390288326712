import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapForm } from '../components';
import { useCreateMapMutation, CreateMapMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId } = useParams();
  const [createPlace, { data, loading }] = useCreateMapMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(`/places/${placeId}/maps`);
    }
  }, [data]);

  const onSubmit = (dataToSubmit: CreateMapMutationVariables) => {
    createPlace({ variables: { ...dataToSubmit, placeId } });
  };
  return (
    <>
      <MapForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('map') })} />
    </>
  );
};
export default Create;
