import React from 'react';
import { TextField } from '@mui/material';
import YouTube from 'react-youtube';
import { Grid } from '@mui/material';

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};

const VideoDescription = (props: { editable: boolean; item: any; setItem: any }) => {
  const { editable, item, setItem } = props;
  return editable ? (
    <Grid container spacing={1} direction="column">
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setItem({ ...item, title: e.target.value })}
          value={item.title}
          fullWidth
          label={'Title'}
          name="title"
          type="text"
          variant="outlined"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setItem({ ...item, videoId: e.target.value })}
          value={item.videoId}
          fullWidth
          label={'Video id'}
          name="videoId"
          type="text"
          variant="outlined"
        />
      </Grid>
    </Grid>
  ) : (
    <YouTube videoId={item.videoId} opts={opts} />
  );
};

export default VideoDescription;
