import common from './common';
import contacts from './contacts';
import features from './features';
import locations from './locations';
import maps from './maps';
import places from './places';
import users from './users';
import pins from './pins';
import notifications from './notifications';
import schedules from './schedules';
import roles from './roles';
import roads from './roads';
import locationCategories from './locationCategories';

export default {
  ...common,
  ...contacts,
  ...features,
  ...locations,
  ...maps,
  ...places,
  ...users,
  ...pins,
  ...notifications,
  ...schedules,
  ...roles,
  ...roads,
  ...locationCategories,
};
