/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { accessToken, refreshToken, selectedPlace } from '../../cache';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('selectedPlace');
    localStorage.removeItem('user');
    client.clearStore();
    accessToken('');
    refreshToken('');
    selectedPlace('');
    navigate('/login', { replace: true });
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}>
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}>
        <Typography variant="overline">{t('user')}</Typography>
        <Typography color="text.secondary" variant="body2">
          {`${user.name} ${user.lastName}`}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1,
          },
        }}>
        <MenuItem onClick={logout}>{t('logout')}</MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
