import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  InputLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import ImageDropzone from '../../../common/components/ImageDropzone';
import { checkPermission } from '../../../utils/check-permission';
import { useParams } from 'react-router-dom';
import { useGetContactsQuery } from '../../../graphql';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  placeType: yup.object().required('Type is required'),
  contact: yup.object().nullable(),
});

const placeTypeOptions = [
  {
    value: 'shopping',
    label: 'Shopping',
  },
  {
    value: 'family',
    label: 'Family',
  },
];

const PlaceForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any }) => {
  const { onSubmit, loading, title, defaultValues } = props;
  const { placeId } = useParams();
  const { t } = useTranslation();
  const { loading: contactsLoading, data: contactsData } = useGetContactsQuery({ variables: { placeId } });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      placeType: placeTypeOptions.find((option) => option.value === defaultValues?.placeType) || undefined,
      contact: contactsData?.contacts.find((option: any) => option.id === defaultValues?.contactId) || undefined,
    },
  });
  const canUpdateName = checkPermission('create_places_all', null);

  const contactsOptions = contactsData?.contacts.map((contact) => {
    return { value: contact.id, label: contact.displayName };
  });

  const getSelectedContact = () => {
    if (defaultValues?.contactId) {
      return contactsOptions.find((option) => option.value === defaultValues.contactId);
    }
  };

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = { ...data };
        dataToSubmit.placeType = data.placeType.value;
        dataToSubmit.contactId = data.contact?.value || null;
        dataToSubmit.walkSpeed = Number(dataToSubmit.walkSpeed);
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            {canUpdateName && (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  label={capitalize(t('name'))}
                  type="text"
                  variant="outlined"
                  {...register('name')}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message || ' '}
                />
              </Grid>
            )}
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('description'))}
                type="text"
                variant="outlined"
                {...register('description')}
                error={Boolean(errors.description)}
                helperText={errors.description?.message || ' '}
              />
            </Grid>
            {canUpdateName && (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  label={capitalize(t('walkSpeed'))}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    step: '0.25',
                  }}
                  variant="outlined"
                  {...register('walkSpeed')}
                  error={Boolean(errors.walkSpeed)}
                  helperText={errors.walkSpeed?.message || ' '}
                />
              </Grid>
            )}
            {canUpdateName && (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('placeType'))}</InputLabel>
                <Controller
                  control={control}
                  name="placeType"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        options={placeTypeOptions}
                        onChange={onChange}
                        value={value || ''}
                        name={name}
                        placeholder={t('selectField', { field: t('placeType') }) as string}
                      />
                    );
                  }}
                />
              </Grid>
            )}
            {contactsLoading ? (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('contact'))}</InputLabel>
                <Controller
                  control={control}
                  name="contact"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        options={contactsOptions}
                        onChange={onChange}
                        value={value !== undefined ? value || '' : getSelectedContact()}
                        name={name}
                        placeholder={t('selectField', { field: t('contact') }) as string}
                        isClearable
                      />
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item md={8} xs={12} sx={{ width: '100%' }}>
              <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('logoUrl'))}</InputLabel>
              <Controller
                control={control}
                name="logoUrl"
                render={({ field: { onChange, value } }) => (
                  <ImageDropzone
                    setFiles={(files) => onChange(files[0] || '')}
                    files={value ? [value] : []}
                    allowedFileTypes={[]}
                    resize={{
                      maxWidth: 360,
                      maxHeight: 360,
                      format: 'PNG',
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default PlaceForm;
