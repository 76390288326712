import React from 'react';
import List from './list';
import Update from './update';
const routes: any = {
  path: 'roles',
  children: [
    { path: '', element: <List /> },
    // { path: 'create', element: <Create /> },
    { path: ':roleId/update', element: <Update /> },
  ],
};
export default routes;
