import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { gqlErrors } from '../../../cache';
import { useReactiveVar } from '@apollo/client';
import Stack from '@mui/material/Stack';

export default function ErrorSnackbar() {
  const errors = useReactiveVar(gqlErrors);
  const [message, setMessage] = React.useState('');
  // const [success, setSuccess] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose: any = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setMessage('');
  };

  useEffect(() => {
    if (errors?.length > 0) {
      // setSuccess(errors?.length > 0 ? false : true);
      setMessage(errors?.length > 0 ? `Error: ${errors[0]}` : 'Success');
      setOpen(true);
      gqlErrors([]);
    }
    /*     return () => {}; */
  }, [errors]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity={'error'} elevation={6} variant="filled">
          {message}
        </MuiAlert>
      </Snackbar>
    </Stack>
  );
}
