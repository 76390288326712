import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetPlacesQuery } from '../../../graphql';
import { useTranslation } from 'react-i18next';
import { selectedPlace } from '../../../cache';
import CloseIcon from '@mui/icons-material/Close';
import ChevronUpDownIcon from '@heroicons/react/24/solid/ChevronUpDownIcon';
import { useReactiveVar } from '@apollo/client';
import { DynamicList } from '../../../common/components';
import { Box, SvgIcon, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';

const setPlaceStorage = (placeId: string) => {
  localStorage.setItem('selectedPlace', placeId);
  selectedPlace(placeId);
};

const SelectedPlace = () => {
  const { t } = useTranslation();
  const { loading, data } = useGetPlacesQuery();
  const selectedPlaceId: string = useReactiveVar(selectedPlace);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const selectPlace = (placeId: string) => {
    setPlaceStorage(placeId);
    setOpen(false);
    navigate(`/places/${placeId}/view`);
  };

  useEffect(() => {
    // Temporal fix for when selectedPlaceId is saved as 'null' string on localstorage
    // This works because IDs are always numbers, so 'null' is NaN
    if (!selectedPlaceId || Number.isNaN(Number(selectedPlaceId))) {
      const localSelectedPlace = localStorage.getItem('selectedPlace');

      if (localSelectedPlace && !Number.isNaN(Number(selectedPlaceId))) {
        setPlaceStorage(localSelectedPlace);
      } else {
        if (data?.places[0]?.id) {
          setPlaceStorage(data?.places[0]?.id);
        }
      }
    }
  }, [data, selectedPlaceId]);

  const user: any = JSON.parse(localStorage.getItem('user') || '{}');
  const selectedPlaceObject = data?.places.find((place: any) => place.id === selectedPlaceId);
  const isSuperAdmin = user?.roles?.indexOf('superadmin') >= 0;
  if (data && !selectedPlaceObject) {
    const newPlaceId = Object.keys(user.permissions)[0] || selectedPlaceId || '';
    setPlaceStorage(`${newPlaceId}`);
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Box
          onClick={() => {
            if (!loading && isSuperAdmin) {
              setOpen(true);
            }
          }}
          sx={{
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            borderRadius: 1,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            p: '12px',
          }}>
          <div>
            <Typography color="inherit" variant="subtitle1">
              {selectedPlaceObject?.name || ''}
            </Typography>
          </div>
          {isSuperAdmin && (
            <SvgIcon fontSize="small" sx={{ color: 'neutral.500' }}>
              <ChevronUpDownIcon />
            </SvgIcon>
          )}
        </Box>
      </Box>
      <Dialog fullWidth onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
          {t('selectTitle', { field: t('place') })}
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box>
          <DynamicList
            onRowClick={(place: any) => selectPlace(place.id)}
            loading={loading}
            objectList={data?.places}
            columns={[
              {
                label: t('name'),
                renderFn: (e: any) => e.name,
              },
              {
                label: t('description'),
                renderFn: (e: any) => e.description,
              },
            ]}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default SelectedPlace;
