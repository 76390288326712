import React, { useState } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Select from 'react-select';
import { Location } from '../../graphql';

const LocationSelector = (props: { locations: Partial<Location>[]; onSubmit: (arg0: string) => void }) => {
  const { locations, onSubmit } = props;
  const { t } = useTranslation();
  const [location, setLocation] = useState('');
  const locationOptions = locations.map((location) => {
    return { value: location.id, label: location.name };
  });

  return (
    <Card>
      <CardHeader title={t('selectField', { field: t('location') })} />
      <CardContent>
        <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('location'))}</InputLabel>
            <Select
              menuPosition="fixed"
              options={locationOptions}
              onChange={(e) => setLocation(e.value)}
              placeholder={t('selectField', { field: t('location') }) as string}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Button variant="contained" color="primary" disabled={!location} onClick={() => onSubmit(location)}>
              {t('submit')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default LocationSelector;
