import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useGetLocationsQuery,
  useGetMapQuery,
  useGetPinsQuery,
  useCreateFeatureMutation,
  useCreateLocationMutation,
} from '../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { FeatureForm } from '../features/components';
import { LocationForm } from '../locations/components';
import LocationSelector from './LocationSelector';
import Button from '@mui/material/Button/Button';
import { CreateFeatureMutationVariables, CreateLocationMutationVariables } from '../../graphql';

const FeatureCreator = () => {
  const { placeId, mapId } = useParams();
  const { t } = useTranslation();
  const { loading: locationsLoading, data: locationsData } = useGetLocationsQuery({ variables: { id: placeId } });
  const { loading: mapsLoading, data: mapData } = useGetMapQuery({ variables: { id: mapId.toString() } });
  const { loading: pinsLoading, data: pinsData } = useGetPinsQuery({ variables: { placeId: placeId } });
  const [isNewLocation, setIsNewLocation] = useState(false);
  const [step, setStep] = useState(0);
  const [locationId, setLocationId] = useState(null);

  const [createLocation, { data: createLocationData, loading: createLocationLoading }] = useCreateLocationMutation();
  const [createFeature, { data: createFeatureData, loading: createFeatureLoading }] = useCreateFeatureMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (createFeatureData) {
      navigate(`/places/${placeId}/maps`);
    }
  }, [createFeatureData]);

  useEffect(() => {
    if (createLocationData) {
      setLocationId(createLocationData.createLocation.location.id);
      setStep(2);
    }
  }, [createLocationData]);

  if (locationsLoading || mapsLoading || pinsLoading) {
    return <></>;
  }

  const selectLocation = (selectedLocationId: string) => {
    setLocationId(selectedLocationId);
    setStep(2);
  };

  const submitLocation = (dataToSubmit: CreateLocationMutationVariables) => {
    createLocation({ variables: { ...dataToSubmit, placeId } });
  };

  const submitFeature = (dataToSubmit: CreateFeatureMutationVariables) => {
    createFeature({ variables: { ...dataToSubmit, locationId } });
  };

  return (
    <>
      {step === 0 && (
        <>
          <Grid container spacing={4} alignItems="center">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setIsNewLocation(false);
                  setStep(1);
                }}>
                {t('selectField', { field: t('location') })}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setIsNewLocation(true);
                  setStep(1);
                }}>
                {t('createTitle', { field: t('location') })}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {step === 1 && (
        <>
          {isNewLocation ? (
            <LocationForm
              onSubmit={submitLocation}
              loading={createLocationLoading}
              title={t('createTitle', { field: t('location') })}
            />
          ) : (
            <LocationSelector onSubmit={selectLocation} locations={locationsData.locations} />
          )}
        </>
      )}
      {step === 2 && (
        <>
          <FeatureForm
            onSubmit={submitFeature}
            location={createLocationData?.createLocation?.location}
            loading={createFeatureLoading}
            title="Crear ubicación"
            pins={pinsData.pins}
            preSelectedMap={mapData.maps[0]}
          />
        </>
      )}
    </>
  );
};
export default FeatureCreator;
