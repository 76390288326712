import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  Dialog,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import Divider from '@mui/material/Divider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Checkbox, FormControlLabel } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { checkPermission } from '../../../utils/check-permission';

const getBase64 = (file: any) => {
  return new Promise<string>((resolve) => {
    let baseURL: string;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result as string;
      resolve(baseURL.split(',')[1]);
    };
  });
};

const Form = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any }) => {
  const { onSubmit, loading, title, defaultValues } = props;
  const { t } = useTranslation();
  const canUpdateLite = checkPermission('update_lite_features_all');
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    color: yup.string().required(t('isRequired', { field: t('color') })),
    width: yup
      .number()
      .required(t('isRequired', { field: t('width') }))
      .min(1),
    unidirectional: yup.boolean(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
        }
      : { name: '', color: '#8B572A', width: 3, unidirectional: false },
  });

  const [open, setOpen] = useState(false);

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        getBase64(data.gpxFile).then((result) => {
          dataToSubmit.gpxFile = result;
          onSubmit(dataToSubmit);
        });
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('name'))}
                type="text"
                variant="outlined"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={canUpdateLite ? t('roadWidth') : `${t('roadWidth')} (${t('payedPlans')})`}
                type="number"
                disabled={!canUpdateLite}
                variant="outlined"
                {...register('width')}
                error={Boolean(errors.width)}
                helperText={errors.width?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <Controller
                control={control}
                name="color"
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      fullWidth
                      label={canUpdateLite ? capitalize(t('color')) : `${capitalize(t('color'))} (${t('payedPlans')})`}
                      type="text"
                      variant="outlined"
                      disabled={!canUpdateLite}
                      value={value}
                      error={Boolean(errors.color)}
                      helperText={errors.color?.message || ' '}
                      onClick={() => setOpen(true)}
                    />
                    <Dialog onClose={() => setOpen(false)} open={open}>
                      <SketchPicker color={value} onChange={(e: any) => onChange(e.hex)} />
                    </Dialog>
                  </>
                )}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Controller
                    name={'unidirectional'}
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                    )}
                  />
                }
                label={capitalize(t('unidirectional'))}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
              <Controller
                name={'gpxFile'}
                control={control}
                defaultValue={true}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Button variant="contained" component="label" startIcon={<UploadFileIcon />}>
                      {capitalize(t('selectGpxFile'))}
                      <input onChange={(file) => onChange(file.target.files[0])} type="file" accept=".gpx" hidden />
                    </Button>
                    {value?.name || ''}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default Form;
