import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextDescription from './TextDescription';
import CarouselDescription from './CarouselDescription';
import VideoDescription from './VideoDescription';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, colors, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
const DESCRIPTION_TYPES = {
  TEXT: 'markdown',
  CAROUSEL: 'carousel',
  VIDEO: 'video',
};

const DynamicDescriptionItem = (props: {
  descriptionItem: any;
  setDescriptionItem: any;
  deleteDescriptionItem: any;
  defaultExpanded: boolean;
  backgroundColor: string;
  moveDescriptionItem: any;
  moveUpEnabled: boolean;
  moveDownEnabled: boolean;
}) => {
  const {
    descriptionItem,
    setDescriptionItem,
    deleteDescriptionItem,
    defaultExpanded,
    backgroundColor,
    moveDescriptionItem,
    moveUpEnabled,
    moveDownEnabled,
  } = props;
  const { t } = useTranslation();
  const [editable, setEditable] = useState(true);

  const getComponentByType = () => {
    if (descriptionItem.type === DESCRIPTION_TYPES.TEXT) {
      return (
        <TextDescription
          editable={editable}
          item={descriptionItem}
          setItem={(newItem: any) => setDescriptionItem(newItem)}
        />
      );
    } else if (descriptionItem.type === DESCRIPTION_TYPES.CAROUSEL) {
      return (
        <CarouselDescription
          editable={editable}
          item={descriptionItem}
          setItem={(newItem: any) => setDescriptionItem(newItem)}
        />
      );
    } else if (descriptionItem.type === DESCRIPTION_TYPES.VIDEO) {
      return (
        <VideoDescription
          editable={editable}
          item={descriptionItem}
          setItem={(newItem: any) => setDescriptionItem(newItem)}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        sx={{
          '& .MuiAccordionSummary-content': {
            justifyContent: 'flex-end',
          },
          backgroundColor,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography sx={{ marginRight: 'auto' }}>{descriptionItem.title}</Typography>
        <Tooltip title={t('Move down')}>
          <IconButton
            sx={{
              color: colors.grey[900],
            }}
            disabled={!moveDownEnabled}
            aria-label="move-down"
            onClick={(event) => {
              event.stopPropagation();
              moveDescriptionItem(1);
            }}>
            <ArrowDownwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Move up')}>
          <IconButton
            sx={{
              color: colors.grey[900],
            }}
            disabled={!moveUpEnabled}
            aria-label="move-up"
            onClick={(event) => {
              event.stopPropagation();
              moveDescriptionItem(-1);
            }}>
            <ArrowUpwardIcon />
          </IconButton>
        </Tooltip>
        {editable ? (
          <Tooltip title={t('Preview')}>
            <IconButton
              sx={{
                color: colors.blue[600],
              }}
              aria-label="Preview"
              onClick={(event) => {
                event.stopPropagation();
                setEditable(false);
              }}>
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t('Edit')}>
            <IconButton
              sx={{
                color: colors.yellow[600],
              }}
              aria-label="edit"
              onClick={(event) => {
                event.stopPropagation();
                setEditable(true);
              }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t('Delete')}>
          <IconButton
            sx={{
              color: colors.red[600],
            }}
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              deleteDescriptionItem();
            }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </AccordionSummary>
      <Paper elevation={8}>
        <AccordionDetails>{getComponentByType()}</AccordionDetails>
      </Paper>
    </Accordion>
  );
};
export default DynamicDescriptionItem;
