export default {
  capitalize: '{{text,capitalize}}',
  isRequired: '{{field}} is required',
  selectField: 'Select {{field}}',
  changeField: 'Change {{field}}',
  createTitle: 'Create {{field}}',
  updateTitle: 'Update {{field}}',
  selectTitle: 'Select {{field}}',
  payedPlans: 'just for payed plans',
  name: 'name',
  description: 'description',
  createdAt: 'created at',
  updatedAt: 'updated at',
  lastName: 'last name',
  actions: 'actions',
  create: 'create',
  type: 'type',
  'Select position': 'Select position',
  submit: 'submit',
  update: 'update',
  restore: 'restore',
  delete: 'delete',
  'Are you sure?': 'Are you sure?',
  cantRevertAction: 'You wont be able to revert this!',
  reversibleAction: 'This could only be reversed with the help of an administrator',
  Yes: 'Yes',
  No: 'No',
  from: 'from',
  to: 'to',
  selectImage: 'Select image',
  multiSelectImage: 'Select images',
  selectImageDescription: 'Drop a image here or click to browse through our machine',
  multiSelectImageDescription: 'Drop one or multiple images here or click to browse through our machine',
  deleteImage: 'Delete image',
  selectImageMaximumSize: '50mb maximum size',
  images: 'images',
  add: 'add',
  dynamicDescriptionExplain:
    'Dynamic descriptions will be displayed by clicking on the corresponding pin and the order in which they are added below.\
    The "Description" type is used to write texts that will be shown with the same format as they are described.\
    The "Images" type is used to upload one or a set of images to be displayed in a carousel style.\
    The "Video" type is used to upload a link to a YouTube video in case you want to add it as a complement to the pin.',
  dynamicDescriptionText: 'description',
  dynamicDescriptionCarousel: 'images',
  dynamicDescriptionVideo: 'video',
  search: 'search',
  setCurrent: 'Set current location',
};
