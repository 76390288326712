import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PinForm } from '../components';
import { useCreatePinMutation, CreatePinMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId } = useParams();
  const [createPin, { data, loading }] = useCreatePinMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(`/places/${placeId}/pins`);
    }
  }, [data]);

  const { t } = useTranslation();
  const onSubmit = (dataToSubmit: CreatePinMutationVariables) => {
    createPin({ variables: { ...dataToSubmit, placeId } });
  };
  return (
    <>
      <PinForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('pin') })} />
    </>
  );
};
export default Create;
