import { Grid, TextField } from '@mui/material';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageDropzone from '../../../../common/components/ImageDropzone';

const CarouselDescription = (props: { editable: boolean; item: any; setItem: any }) => {
  const { editable, item, setItem } = props;
  return editable ? (
    <Grid container spacing={1} direction="column">
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setItem({ ...item, title: e.target.value })}
          value={item.title}
          fullWidth
          label={'Title'}
          name="title"
          type="text"
          variant="outlined"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ImageDropzone
          multi
          setFiles={(files) => setItem({ ...item, images: files.map((file: string) => ({ url: file })) })}
          files={item.images?.map((image: any) => image.url) || []}
          allowedFileTypes={[]}
          resize={{
            maxWidth: 1980,
            maxHeight: 1980,
            format: 'PNG',
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <Carousel>
      {item.images?.map((image: any, index: number) => (
        <div key={`image_${index}`}>
          <img src={image.url} />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselDescription;
