import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScheduleForm } from '../components';
import { useUpdateScheduleMutation, useGetScheduleQuery, UpdateScheduleMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { scheduleId, placeId, locationId } = useParams();
  const [updateSchedule, { data: updateData, loading: updateLoading }] = useUpdateScheduleMutation();
  const { loading, data } = useGetScheduleQuery({ variables: { id: scheduleId.toString() } });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/locations/${locationId}/schedules`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdateScheduleMutationVariables) => {
    updateSchedule({ variables: { ...dataToSubmit, locationId, id: scheduleId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <ScheduleForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('schedule') })}
        defaultValues={data?.schedules[0]}
      />
    </>
  );
};
export default Update;
