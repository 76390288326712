import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import { colors } from '@mui/material';
import TextsItem from './InfographicsItemTextsItem';

const DEFAULT_VALUES = {
  title: 'Text',
  content: '',
  font: '',
  fontSize: 12,
  textColor: '#000000',
  backgroundColor: '#ffffff',
  width: 300,
  initialPosition: [0.01, 0.01],
  finalPosition: [0.01, 0.01],
};

const InfographicsItemTexts = (props: { texts: any[]; setTexts: any }) => {
  const { texts, setTexts } = props;
  const { t } = useTranslation();
  const [defaultExpanded, setDefaultExpanded] = useState(false);

  const addTextsItem = async () => {
    if (!defaultExpanded) {
      setDefaultExpanded(true);
    }
    const newTexts = texts.concat([{ ...DEFAULT_VALUES, title: 'Text', key: uuid() }]);
    setTexts(newTexts);
  };

  const setTextsItem = (newTextsItem: any, index: number) => {
    const newTexts = texts;
    newTexts[index] = newTextsItem;
    setTexts(newTexts);
  };

  const deleteTextsItem = (index: number) => {
    const newTexts = texts.filter((_, itemIndex) => itemIndex !== index);
    setTexts(newTexts);
  };

  const moveTextsItem = (from: number, to: number) => {
    const newTexts = texts;
    const item = newTexts.splice(from, 1)[0];
    newTexts.splice(to, 0, item);
    setTexts(newTexts);
  };

  return (
    <>
      {texts.map((item, index) => (
        <TextsItem
          key={item.key}
          textsItem={item}
          setTextsItem={(newTextsItem: any) => setTextsItem(newTextsItem, index)}
          deleteTextsItem={() => deleteTextsItem(index)}
          defaultExpanded={defaultExpanded}
          backgroundColor={index % 2 === 0 ? colors.grey[50] : colors.grey[100]}
          moveTextsItem={(relativeMovement: number) => moveTextsItem(index, index + relativeMovement)}
          moveUpEnabled={index > 0}
          moveDownEnabled={index < texts.length - 1}
        />
      ))}
      <Box m={1} display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Button
          onClick={() => addTextsItem()}
          style={{ justifyContent: 'flex-end' }}
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<AddCircleIcon />}>
          {`${t('add')} ${t('texts')}`}
        </Button>
      </Box>
    </>
  );
};
export default InfographicsItemTexts;
