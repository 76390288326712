export default {
  mapsDescription:
    'Cada mapa representa un nuevo piso para un usuario. No se puede tener mas de 1 mapa en un mismo piso y el piso por defecto debe ser el 0',
  roadsDescription:
    'Un camino es utilizado como referencia por un usuario para saber los lugares en es posible transitar. Un mapa puede tener muchos caminos en caso de que no exista solo una ruta circular. Además, puede definir colores a cada camino en caso se desee advertir o informar al usuario sobre una diferencia con los otros caminos.',
  map: 'mapa',
  maps: 'mapas',
  tileUrl: 'url de tiles',
  floor: 'piso',
  pointNe: 'punto noreste',
  pointSw: 'punto suroeste',
  road: 'camino',
  roads: 'caminos',
  initialZoom: 'Zoom inicial',
  minZoom: 'Zoom mínimo',
  maxZoom: 'Zoom máximo',
  nodes: 'nodos',
  nodesRequired: 'El camino debe tener al menos dos puntos en el mapa',
  addFeature: 'Añadir ubicación',
  distance: 'distancia',
};
