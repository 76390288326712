import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import logo from '../../assets/images/logo.png';

export const Layout = (props) => {
  const { children } = props;

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        backgroundColor: '#1C2536',
        height: '100vh',
      }}>
      <Grid container sx={{ flex: '1 1 auto' }}>
        <Grid
          xs={12}
          lg={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}>
          <Box
            component="header"
            sx={{
              width: '100%',
              marginTop: '3em',
              marginBottom: '3em',
            }}>
            <img alt="Logo" src={logo} style={{ width: '20em' }} />
          </Box>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
