export default {
  featuresDescription:
    'Una ubicación es usada para definir la posición de un punto de interés, la cual se mostrará como un pin del punto de interés en el mapa seleccionado.',
  feature: 'ubicación',
  features: 'ubicaciones',
  center: 'centro',
  zoomRange: 'rango de zoom',
  priorityDescription:
    'Usado para definir el nivel de zoom mínimo y máximo en que se verá el pin, donde por ejemplo: Si el rango superior está en "Media Alta" significa que el pin no se vera con el zoom más lejano. En cambio, si el rango inferior está en "Media Baja", significa que el pin no se verá cuando el zoom está al máximo. Esto puede ser especialmente útil cuando se tiene algún pin que no desea mostrarse en el zoom mas alejado ya que se sobrepone con otros más relevantes, o también cuando no se quiere mostrar en el zoom más cercano debido a que se usa para demarcar una zona mayor a la visible.',
  fromZoom: 'Zoom máximo',
  toZoom: 'Zoom mínimo',
};
