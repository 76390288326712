import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  CardActions,
  CircularProgress,
  capitalize,
  InputLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { GetAllRolesQuery, GetPlacesQuery } from '../../../graphql';

const UserForm = (props: {
  onSubmit: any;
  loading: boolean;
  title: string;
  defaultValues?: any;
  places: GetPlacesQuery['places'];
  roles: GetAllRolesQuery['roles'];
}) => {
  const { t } = useTranslation();

  const phoneRegExp =
    /^(\+((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?)?$/;

  const schema = yup.object().shape({
    email: yup.string().email(),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid, it must have the country code number'),
    name: yup.string(),
    lastName: yup.string(),
    roles: yup.array(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    ),
  });

  const { onSubmit, loading, title, defaultValues, places, roles } = props;
  const defaultRoles = defaultValues.roles.map((role: any) => role.id);

  const selectOptions = roles.map((role) => {
    const place = places.find((place) => place.id === role.placeId);
    return { value: role.id, label: `${place?.name || 'General'} - ${role.name}` };
  });
  selectOptions.sort((a, b) => (a.label < b.label ? -1 : 1));
  const genderOptions = [
    { value: 'male', label: 'Hombre' },
    { value: 'female', label: 'Mujer' },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      roles: selectOptions.filter((option) => defaultRoles.includes(option.value)),
    },
  });
  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        dataToSubmit.roles = data.roles.map((roleOption: any) => roleOption.value);
        dataToSubmit.gender = data.gender?.value || '';
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label={capitalize(t('email'))}
              type="text"
              variant="outlined"
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message || ' '}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label={capitalize(t('phone'))}
              type="text"
              variant="outlined"
              {...register('phone')}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message || ' '}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label={capitalize(t('name'))}
              type="text"
              variant="outlined"
              {...register('name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ' '}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label={capitalize(t('lastName'))}
              type="text"
              variant="outlined"
              {...register('lastName')}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message || ' '}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('gender'))}</InputLabel>
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Select
                    menuPosition="fixed"
                    options={genderOptions}
                    onChange={onChange}
                    value={genderOptions.find((e) => e.value == value)}
                    name={name}
                    placeholder={t('selectField', { field: t('gender') }) as string}
                    isClearable
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('roles'))}</InputLabel>
            <Controller
              control={control}
              name="roles"
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Select
                    menuPosition="fixed"
                    options={selectOptions}
                    onChange={onChange}
                    value={value || ''}
                    name={name}
                    placeholder={t('selectField', { field: t('roles') }) as string}
                    isMulti
                  />
                );
              }}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default UserForm;
