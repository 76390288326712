export default {
  featuresDescription:
    'A location is used to define the position of a point of interest, which will be displayed as a point of interest pin on the selected map.',
  feature: 'feature',
  features: 'features',
  center: 'center',
  zoomRange: 'zoom range',
  priorityDescription:
    'Used to define the minimum and maximum zoom level at which the pin will be visible, for example: If the upper range is set to "Medium High" it means that the pin will not be visible at the farthest zoom. On the other hand, if the lower range is set to "Medium Low", it means that the pin will not be visible when the zoom is at maximum. This can be especially useful when you have a pin that you don\'t want to display at the farthest zoom because it overlaps with other more relevant ones, or also when you don\'t want to display it at the closest zoom because it is used to demarcate an area larger than the visible one.',
    fromZoom: 'max zoom',
    toZoom: 'min zoom',
};
