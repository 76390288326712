import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationCategoryForm } from '../components';
import { useCreateLocationCategoryMutation, CreateLocationCategoryMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId } = useParams();
  const [createLocationCategory, { data, loading }] = useCreateLocationCategoryMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      navigate(`/places/${placeId}/categories`);
    }
  }, [data]);
  const { t } = useTranslation();
  const onSubmit = (dataToSubmit: CreateLocationCategoryMutationVariables) => {
    createLocationCategory({ variables: { ...dataToSubmit, placeId } });
  };
  return (
    <>
      <LocationCategoryForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('category') })} />
    </>
  );
};
export default Create;
