import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserForm } from '../components';
import {
  useUpdateUserMutation,
  useGetUserQuery,
  UpdateUserMutationVariables,
  useGetAllRolesQuery,
  useGetPlacesQuery,
} from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const [updateUser, { data: updateData, loading: updateLoading }] = useUpdateUserMutation();
  const { loading, data } = useGetUserQuery({ variables: { uid: userId.toString() } });
  const { data: rolesData, loading: rolesLoading } = useGetAllRolesQuery();
  const { data: placesData, loading: placesLoading } = useGetPlacesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      navigate('/users');
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdateUserMutationVariables) => {
    updateUser({ variables: { ...dataToSubmit, uid: userId } });
  };

  if (loading || placesLoading || rolesLoading) {
    return <></>;
  }
  return (
    <>
      <UserForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('user') })}
        defaultValues={data?.users[0]}
        roles={rolesData.roles}
        places={placesData.places}
      />
    </>
  );
};
export default Update;
