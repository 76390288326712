import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import Select from 'react-select';
import ImageDropzone from '../../../common/components/ImageDropzone';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import DynamicDescription from './DynamicDescription';
import Infographics from './Infographics';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import { checkPermission } from '../../../utils/check-permission';
import { useGetContactsQuery, useGetLocationCategoriesQuery, Location } from '../../../graphql';

const LocationForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: Partial<Location> & {locationCategoriesIds: any[]} }) => {
  const { onSubmit, loading, title, defaultValues } = props;
  const { placeId } = useParams();
  const { t } = useTranslation();
  const canUpdateLite = checkPermission('update_lite_features_all');
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    logoUrl: yup.string().url(),
    canReview: yup.boolean(),
    dynamicDescription: yup.mixed(),
    infographics: yup.mixed(),
    contact: yup.object().nullable(),
    locationCategories: yup.array(yup.object()),
  });
  const { loading: contactsLoading, data: contactsData } = useGetContactsQuery({ variables: { placeId } });
  const { loading: locationCategoriesLoading, data: locationCategoriesData } = useGetLocationCategoriesQuery({ variables: { id: placeId } });

  const contactsOptions = contactsData?.contacts.map((contact) => {
    return { value: contact.id, label: contact.displayName };
  });

  const locationCategoriesOptions = locationCategoriesData?.locationCategories.map((category) => {
    return { value: category.id, label: category.name };
  });
  const getSelectedContact = () => {
    if (defaultValues?.contactId) {
      return contactsOptions.find((option) => option.value === defaultValues.contactId);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      dynamicDescription: defaultValues?.dynamicDescription || [],
      infographics: defaultValues?.infographics || [],
      contact: contactsData?.contacts.find((option: any) => option.id === defaultValues?.contactId) || undefined,
      locationCategories: defaultValues?.locationCategories?.map(category => ({ label: category.name, value: category.id })) || [],
    },
  });

  const dynamicDescription = watch('dynamicDescription');
  const [selectedTab, setSelectedTab] = useState(0);
  const infographics = watch('infographics');

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        dataToSubmit.contactId = data.contact?.value || null;
        dataToSubmit.locationCategoriesIds = data.locationCategories?.map((option: any) => option.value) || [];
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <Tabs
                value={selectedTab}
                onChange={(_, value) => setSelectedTab(value)}
                indicatorColor="secondary"
                textColor="inherit">
                <Tab label={capitalize(t('general'))} />
                <Tab label={capitalize(t('dynamicDescription'))} />
                {canUpdateLite && (<Tab label={capitalize(t('infographics'))} />)}
              </Tabs>
            </Grid>
            <div hidden={selectedTab !== 0}>
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  label={capitalize(t('name'))}
                  type="text"
                  variant="outlined"
                  {...register('name')}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message || ' '}
                />
              </Grid>
              {contactsLoading ? (
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('contactInfo'))}</InputLabel>
                  <Controller
                    control={control}
                    name="contact"
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <Select
                          options={contactsOptions}
                          onChange={onChange}
                          value={value !== undefined ? value || '' : getSelectedContact()}
                          name={name}
                          placeholder={t('selectField', { field: t('contact') }) as string}
                          isClearable
                        />
                      );
                    }}
                  />
                </Grid>
              )}
              {locationCategoriesLoading ? (
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('categories'))}</InputLabel>
                  <Controller
                    control={control}
                    name="locationCategories"
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <Select
                          options={locationCategoriesOptions}
                          onChange={onChange}
                          value={value}
                          name={name}
                          placeholder={t('selectField', { field: t('locationCategory') }) as string}
                          isClearable
                          isMulti
                        />
                      );
                    }}
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
                <FormControlLabel
                  control={
                    <Controller
                      name={'canReview'}
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                      )}
                    />
                  }
                  label={capitalize(t('canReview'))}
                />
              </Grid>
              <Grid item md={8} xs={12} sx={{ width: '100%' }}>
                <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('logoUrl'))}</InputLabel>
                <Controller
                  control={control}
                  name="logoUrl"
                  render={({ field: { onChange, value } }) => (
                    <ImageDropzone
                      setFiles={(files) => onChange(files[0] || '')}
                      files={value ? [value] : []}
                      allowedFileTypes={[]}
                      resize={{
                        maxWidth: 360,
                        maxHeight: 360,
                        format: 'PNG',
                      }}
                    />
                  )}
                />
              </Grid>
            </div>

            <div hidden={selectedTab !== 1}>
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('dynamicDescription'))}</InputLabel>
                <Controller
                  control={control}
                  name="dynamicDescription"
                  render={({ field: { onChange } }) => (
                    <DynamicDescription description={dynamicDescription} setDescription={onChange} />
                  )}
                />
              </Grid>
            </div>

            <div hidden={selectedTab !== 2}>
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('infographics'))}</InputLabel>
                <Controller
                  control={control}
                  name="infographics"
                  render={({ field: { onChange } }) => (
                    <Infographics infographics={infographics} setInfographics={onChange} />
                  )}
                />
              </Grid>
            </div>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default LocationForm;
