import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlaceForm } from '../components';
import { useCreatePlaceMutation, CreatePlaceMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const [createPlace, { data, loading }] = useCreatePlaceMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate('/places');
    }
  }, [data]);

  const onSubmit = (dataToSubmit: CreatePlaceMutationVariables) => {
    createPlace({ variables: dataToSubmit });
  };
  return (
    <>
      <PlaceForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('place') })} />
    </>
  );
};
export default Create;
