import React, { useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Polyline, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MapModal } from '../../../common/components';

// @Todo add map typing, its bugged because of point and pointGeo inconsistencies in api schema
const MapPreview = (props: { map?: any; setValue?: (name: string, value: any) => any, customTile?: string }) => {
  const { map, setValue, customTile } = props;
  const bounds: any = map.pointNe && map.pointSw ? [map.pointNe, map.pointSw] : null;
  if (!bounds) return <></>;

  function DraggableMarker(props: { point: 'pointNe' | 'pointSw' }) {
    const { point } = props;
    const position = point === 'pointNe' ? bounds[0] : bounds[1];
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setValue(point, marker.getLatLng());
          }
        },
      }),
      [],
    );

    return <Marker draggable eventHandlers={eventHandlers} position={position} ref={markerRef} />;
  }

  const n: number = bounds[0].lat;
  const e: number = bounds[0].lng;
  const s: number = bounds[1].lat;
  const w: number = bounds[1].lng;
  const boundsPath: any = [
    [n, e],
    [n, w],
    [s, w],
    [s, e],
    [n, e],
  ];

  return (
    <MapModal
      bounds={bounds}
      title={'Seleccionar contornos del mapa'}
      customTile={map?.tileUrl}
    >
      <DraggableMarker point="pointNe" />
      <DraggableMarker point="pointSw" />
      <Polyline pathOptions={{ color: '#890F0D', weight: 3 }} positions={boundsPath} />
      {map.roads?.map((road: any) => {
        return (
          <Polyline
            key={`road_${road.id}`}
            pathOptions={{ color: road.color, weight: road.width }}
            positions={road.nodes.map((node: any) => [node.pointGeo.latitude, node.pointGeo.longitude])}
          />
        );
      })}
    </MapModal>
  );
};
export default MapPreview;
