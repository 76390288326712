import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Typography, IconButton, Box, colors, CircularProgress } from '@mui/material';
import { useUploadFileMutation } from '../../../graphql';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CloseIcon from '@mui/icons-material/Close';
import Resizer from 'react-image-file-resizer';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

/* const classes = {
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      cursor: 'pointer',
    },
  },
  dragAccepted: {
    background: theme.palette.primary.light,
  },
  dragRejected: {
    background: theme.palette.error.light,
    '& a': {
      color: theme.palette.primary.dark,
    },
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: '#FF0000',
  },
  list: {
    maxHeight: 320,
  },
  img: {
    objectFit: 'contain',
    width: '100%',
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  deleteButton: {
    color: colors.red[600],
    float: 'right',
  },
}; */

const getBase64 = (file: any) => {
  return new Promise<string>((resolve) => {
    let baseURL: string;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result as string;
      resolve(baseURL);
    };
  });
};

const ImageDropzone = (props: {
  setFiles: (arg0: any) => void;
  files: string[];
  allowedFileTypes: ('.png' | '.svg' | '.jpg' | '.jpeg')[];
  multi?: boolean;
  resize?: {
    maxWidth: number;
    maxHeight: number;
    format: 'JPEG' | 'PNG' | 'WEBP';
    minWidth?: number;
    minHeight?: number;
  };
}) => {
  const { setFiles, files, allowedFileTypes, multi, resize } = props;

  const { t } = useTranslation();
  const hiddenFileInput = React.useRef(null);

  const [error, setError] = useState(null);

  const [uploadFile, { loading }] = useUploadFileMutation({
    onCompleted(data) {
      if (multi) {
        setFiles([...(files || []), data.uploadFile.file.url]);
      } else {
        setFiles([data.uploadFile.file.url]);
      }
    },
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleDelete = () => {
    if (multi) {
      setFiles(files.filter((file, index) => index !== currentImageIndex));
      setCurrentImageIndex(Math.max(currentImageIndex - 1, 0));
    } else {
      setFiles([]);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      acceptedFiles.forEach((file: any) => {
        if (resize) {
          Resizer.imageFileResizer(
            file,
            resize.maxWidth,
            resize.maxHeight,
            resize.format,
            100,
            0,
            (uri) => {
              uploadFile({ variables: { data: uri as string } });
            },
            'base64',
          );
        } else {
          getBase64(file).then((data) => {
            uploadFile({ variables: { data } });
          });
        }
      });

      const rejectedFile = rejectedFiles[0];
      if (rejectedFile) {
        setError(rejectedFile.errors[0]?.message);
      }
    },
    [uploadFile],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: { 'image/*': allowedFileTypes },
    multiple: multi,
    onDrop: handleDrop,
    preventDropOnDocument: true,
    maxSize: 50000000, // 50mb
  });

  const inputProps = getInputProps();

  return (
    <>
      {(multi || files?.length === 0) && (
        <div
          onClick={() => {
            if (!loading) {
              hiddenFileInput.current.click();
            }
          }}>
          <input
            {...inputProps}
            onChange={(event) => {
              getInputProps().onChange(event);
            }}
            ref={hiddenFileInput}
          />
          <Box
            /* className={clsx({
              [classes.dropZone]: true,
              [classes.dragAccepted]: isDragAccept,
              [classes.dragRejected]: isDragReject,
            })} */
            sx={{
              ...baseStyle,
              ...(isDragAccept ? acceptStyle : {}),
              ...(isDragReject ? rejectStyle : {}),
            }}
            {...getRootProps()}>
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                <Typography gutterBottom variant="h3">
                  {t(multi ? 'multiSelectImage' : 'selectImage')}
                </Typography>
                {error ? (
                  <>
                    <Typography sx={{ color: colors.red[600] }} variant="body1">
                      {`Error: ${error}`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body1">
                      {t(multi ? 'multiSelectImageDescription' : 'selectImageDescription')}
                    </Typography>
                    <Typography variant="body1">{t('selectImageMaximumSize')}</Typography>
                  </>
                )}
              </div>
            )}
          </Box>
        </div>
      )}
      {files?.length > 0 && (
        <>
          <IconButton
            aria-label="close"
            sx={{
              float: 'right',
            }}
            onClick={() => {
              handleDelete();
            }}>
            <CloseIcon
              sx={{
                color: colors.red[600],
              }}
            />
          </IconButton>
          <Carousel onChange={(index) => setCurrentImageIndex(index)} selectedItem={currentImageIndex}>
            {files?.map((file, index) => (
              <div key={`file_${index}`}>
                <img style={{ maxWidth: 300, maxHeight: 300 }} src={file} />
              </div>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
};

export default ImageDropzone;
