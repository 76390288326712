import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationCategoryForm } from '../components';
import { useUpdatelocationCategoryMutation, useGetLocationCategoryQuery, UpdatelocationCategoryMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { locationCategoryId, placeId } = useParams();
  const [updateLocation, { data: updateData, loading: updateLoading }] = useUpdatelocationCategoryMutation();
  const { loading, data } = useGetLocationCategoryQuery({ variables: { id: locationCategoryId.toString() } });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/categories`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdatelocationCategoryMutationVariables) => {
    updateLocation({ variables: { ...dataToSubmit, id: locationCategoryId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <LocationCategoryForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('category') })}
        defaultValues={data?.locationCategories[0]}
      />
    </>
  );
};
export default Update;
