import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationForm } from '../components';
import { useCreateLocationMutation, CreateLocationMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId } = useParams();
  const [createLocation, { data, loading }] = useCreateLocationMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      navigate(`/places/${placeId}/locations`);
    }
  }, [data]);
  const { t } = useTranslation();
  const onSubmit = (dataToSubmit: CreateLocationMutationVariables) => {
    createLocation({ variables: { ...dataToSubmit, placeId } });
  };
  return (
    <>
      <LocationForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('location') })} />
    </>
  );
};
export default Create;
