import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationForm } from '../components';
import { useUpdateLocationMutation, useGetLocationQuery, UpdateLocationMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { locationId, placeId } = useParams();
  const [updateLocation, { data: updateData, loading: updateLoading }] = useUpdateLocationMutation();
  const { loading, data } = useGetLocationQuery({ variables: { id: locationId.toString() } });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/locations`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdateLocationMutationVariables) => {
    updateLocation({ variables: { ...dataToSubmit, id: locationId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <LocationForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('location') })}
        // @todo fix this typing
        defaultValues={data?.locations[0] as any}
      />
    </>
  );
};
export default Update;
