import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PinForm } from '../components';
import { useUpdatePinMutation, useGetPinQuery, UpdatePinMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { pinId, placeId } = useParams();
  const [updatePin, { data: updateData, loading: updateLoading }] = useUpdatePinMutation();
  const { loading, data } = useGetPinQuery({ variables: { id: pinId.toString() } });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/pins`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdatePinMutationVariables) => {
    updatePin({ variables: { ...dataToSubmit, id: pinId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <PinForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('pin') })}
        defaultValues={data?.pins[0]}
      />
    </>
  );
};
export default Update;
