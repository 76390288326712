import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, colors, Typography, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Grid, TextField } from '@mui/material';
import { SketchPicker } from 'react-color';

const TextsItem = (props: {
  textsItem: any;
  setTextsItem: any;
  deleteTextsItem: any;
  defaultExpanded: boolean;
  backgroundColor: string;
  moveTextsItem: any;
  moveUpEnabled: boolean;
  moveDownEnabled: boolean;
}) => {
  const {
    textsItem,
    setTextsItem,
    deleteTextsItem,
    defaultExpanded,
    backgroundColor,
    moveTextsItem,
    moveUpEnabled,
    moveDownEnabled,
  } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(0);

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        sx={{
          '& .MuiAccordionSummary-content': {
            justifyContent: 'flex-end',
          },
          backgroundColor,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography sx={{ marginRight: 'auto' }}>{textsItem.title || 'Text'}</Typography>
        <Tooltip title={t('Move down')}>
          <IconButton
            sx={{
              color: colors.grey[900],
            }}
            disabled={!moveDownEnabled}
            aria-label="move-down"
            onClick={(event) => {
              event.stopPropagation();
              moveTextsItem(1);
            }}>
            <ArrowDownwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Move up')}>
          <IconButton
            sx={{
              color: colors.grey[900],
            }}
            disabled={!moveUpEnabled}
            aria-label="move-up"
            onClick={(event) => {
              event.stopPropagation();
              moveTextsItem(-1);
            }}>
            <ArrowUpwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('Delete')}>
          <IconButton
            sx={{
              color: colors.red[600],
            }}
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              deleteTextsItem();
            }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </AccordionSummary>
      <Paper elevation={8}>
        <AccordionDetails>
          <Grid container spacing={1} direction="column">
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) => setTextsItem({ ...textsItem, content: e.target.value })}
                value={textsItem.content}
                fullWidth
                label={'Content'}
                name="content"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) => setTextsItem({ ...textsItem, fontFamily: e.target.value })}
                value={textsItem.fontFamily}
                fullWidth
                label={'fontFamily'}
                name="fontFamily"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) => setTextsItem({ ...textsItem, fontSize: Number(e.target.value) })}
                value={textsItem.fontSize}
                fullWidth
                label={'FontSize'}
                name="fontSize"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <>
                <TextField
                  fullWidth
                  label={t('textColor')}
                  name="textColor"
                  type="text"
                  variant="outlined"
                  value={textsItem.textColor}
                  onClick={() => setOpen(1)}
                />
                <Dialog onClose={() => setOpen(0)} open={open === 1}>
                  <SketchPicker
                    color={textsItem.textColor}
                    onChange={(e: any) => setTextsItem({ ...textsItem, textColor: e.hex })}
                  />
                </Dialog>
              </>
            </Grid>
            <Grid item md={12} xs={12}>
              <>
                <TextField
                  fullWidth
                  label={t('backgroundColor')}
                  name="backgroundColor"
                  type="text"
                  variant="outlined"
                  value={textsItem.backgroundColor}
                  onClick={() => setOpen(2)}
                />
                <Dialog onClose={() => setOpen(0)} open={open === 2}>
                  <SketchPicker
                    color={textsItem.backgroundColor}
                    onChange={(e: any) => setTextsItem({ ...textsItem, backgroundColor: e.hex })}
                  />
                </Dialog>
              </>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) => setTextsItem({ ...textsItem, width: Number(e.target.value) })}
                inputProps={{
                  min: 50,
                  max: 350,
                }}
                value={textsItem.width}
                fullWidth
                label={'width'}
                name="width"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) =>
                  setTextsItem({
                    ...textsItem,
                    initialPosition: [Number(e.target.value), textsItem.initialPosition[1]],
                    finalPosition: [Number(e.target.value), textsItem.finalPosition[1]]
                  })
                }
                inputProps={{
                  step: '0.01'
                }}
                value={textsItem.initialPosition[0]}
                fullWidth
                label={'initialPositionX'}
                name="initialPositionX"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) =>
                  setTextsItem({
                    ...textsItem,
                    initialPosition: [textsItem.initialPosition[0], Number(e.target.value)],
                    finalPosition: [textsItem.finalPosition[0], Number(e.target.value)]
                  })
                }
                inputProps={{
                  step: '0.01'
                }}
                value={textsItem.initialPosition[1]}
                fullWidth
                label={'initialPositionY'}
                name="initialPositionY"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) =>
                  setTextsItem({ ...textsItem, finalPosition: [Number(e.target.value), textsItem.finalPosition[1]] })
                }
                inputProps={{
                  step: '0.01'
                }}
                value={textsItem.finalPosition[0]}
                fullWidth
                label={'finalPositionX'}
                name="finalPositionX"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                onChange={(e) =>
                  setTextsItem({ ...textsItem, finalPosition: [textsItem.finalPosition[0], Number(e.target.value)] })
                }
                inputProps={{
                  step: '0.01'
                }}
                value={textsItem.finalPosition[1]}
                fullWidth
                label={'finalPositionY'}
                name="finalPositionY"
                type="number"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Paper>
    </Accordion>
  );
};
export default TextsItem;
