import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Marker, Tooltip, MapContainer, TileLayer, useMapEvents, Polyline } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import L, { LeafletMouseEvent, LeafletEvent } from 'leaflet';
import '../../../../node_modules/leaflet/dist/leaflet.css';
import { Box, Button, CircularProgress, Dialog, DialogTitle, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Road, Feature } from '../../../graphql';

//////////////////////////////////////////////////////////////////////////////
// Fix to load marker image
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
});
//////////////////////////////////////////////////////////////////////////////

const EventsHandler = (props: {
  setValue: (arg0: any) => void;
  setCenter: (arg0: any) => void;
  setCurrentZoom: (arg: string) => void
}): null => {
  const { setValue, setCurrentZoom } = props;

  useMapEvents({
    click(e: LeafletMouseEvent) {
      setValue(e.latlng);
    },
    zoomend(event: LeafletEvent) {
      const zoom = event.target.getZoom();
      setCurrentZoom(zoom);
    },
  });

  return null;
};

function DraggableMarker(props: { position: any; setValue: any }) {
  const { position, setValue } = props;
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setValue(marker.getLatLng());
        }
      },
    }),
    [setValue],
  );

  return <Marker draggable eventHandlers={eventHandlers} position={position} ref={markerRef} />;
}

const LocationPicker = (props: any) => {
  const LeafIcon: any = L.Icon.extend({
    options: {},
  });
  const greenIcon = new LeafIcon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
    iconAnchor: [12, 39],
  });

  const { t } = useTranslation();
  const { featureId } = useParams();
  const { value, setValue, error, label, initialZoom, roads, features, customTile } = props;
  const [open, setOpen] = useState(false);
  const [center, setCenter] = useState(value || [-33.4, -70.6]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(initialZoom || 15);
  const hasValidValue = value?.lat && value?.lng;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCurrentLocation({ lat, lng });
          setAccuracy(position.coords.accuracy);
        },
        (e) => {
          console.error(e.message);
        },
        { enableHighAccuracy: true, maximumAge: 2000, timeout: 0 }
      );
    }
  }, [])

  return (
    <Grid container spacing={1} direction="row">
      <Grid item xs={8}>
        <TextField
          inputProps={{
            readOnly: true,
          }}
          value={hasValidValue ? `${value.lat}, ${value.lng}` : t('Select position')}
          fullWidth
          label={label}
          type="text"
          variant="outlined"
          onClick={() => setOpen(true)}
          error={error}
          helperText={error?.message || ' '}
        />
      </Grid>

      <Grid item xs={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setValue(currentLocation);
          }}>
          {currentLocation ? t('setCurrent') : <CircularProgress />}
        </Button>
        <br />
        {accuracy && `${t('precision')} ${Math.round(accuracy * 10) / 10} m`}
      </Grid>

      <Dialog fullScreen onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" display={'flex'} alignItems={'center'}>
          Seleccionar posición
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 'auto' }}
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </DialogTitle>
        <MapContainer center={center} zoom={initialZoom || 15} maxZoom={21} style={{ width: '100%', height: '100vh' }}>
          <TileLayer
            maxZoom={19}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={`${process.env.REACT_APP_TILE_SERVER_URL}/{z}/{x}/{y}.png`}
          />
          {customTile && (
            <TileLayer
              maxZoom={21}
              url={customTile}
            />
          )}
          {roads &&
            roads.map((road: Partial<Road>) => {
              return road.nodes?.length > 1 ? (
                <Polyline
                  pmIgnore={false}
                  key={`road_${road?.id}`}
                  pathOptions={{ color: road.color || '#755c42', weight: road.width || 3 }}
                  positions={road?.nodes?.map((node: any) => [node.pointGeo.latitude, node.pointGeo.longitude])}
                />
              ) : null;
            })}
          {features &&
            features.map((feature: Partial<Feature>) => {
              const shouldRender = currentZoom >= feature.fromZoom && currentZoom <= (feature.toZoom || 30);
              return shouldRender ? (
                <Marker
                  key={feature.id}
                  position={[feature.centerGeo.latitude, feature.centerGeo.longitude]}
                  icon={greenIcon}
                >
                  <Tooltip direction="bottom" opacity={0.8} permanent>
                    <Box sx={{ textAlign: 'center' }}>
                      <Box>
                        {feature.name}
                      </Box>
                      <Box>
                        {feature.location.name}
                      </Box>
                    </Box>
                  </Tooltip>
                </Marker>
              ) : null;
            })}
          {hasValidValue && <DraggableMarker position={value} setValue={setValue} />}
          <EventsHandler setValue={setValue} setCenter={setCenter} setCurrentZoom={setCurrentZoom} />
        </MapContainer>
      </Dialog>
    </Grid>
  );
};

export default LocationPicker;
