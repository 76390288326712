export default {
  mapsDescription:
    'Each map represents a new floor for a user. You cannot have more than 1 map on the same floor and the default floor must be 0',
  roadsDescription:
    'A path is used as a reference by a user to know the places where it is possible to travel. A map can have many roads in case there is not just one circular route. In addition, you can define colors for each path in case you want to warn or inform the user about a difference with the other paths.',
  map: 'map',
  maps: 'maps',
  tileUrl: 'tile url',
  floor: 'floor',
  pointNe: 'northeast point',
  pointSw: 'southwest point',
  road: 'road',
  roads: 'roads',
  initialZoom: 'Initial zoom',
  minZoom: 'Min zoom',
  maxZoom: 'Max zoom',
  nodesRequired: 'The road must have at least two points in the map',
  addFeature: 'Add feature',
  distance: 'distance',
};
