import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from './Form';
import { useCreateRoadFromFileMutation, CreateRoadFromFileMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const { placeId, mapId } = useParams();
  const [createRoad, { data: createData, loading: createLoading }] = useCreateRoadFromFileMutation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (createData) {
      navigate(`/places/${placeId}/maps/${mapId}/roads`);
    }
  }, [createData]);

  const onSubmit = (dataToSubmit: CreateRoadFromFileMutationVariables) => {
    createRoad({ variables: { ...dataToSubmit, mapId } });
  };
  return (
    <>
      <Form onSubmit={onSubmit} loading={createLoading} title={t('createTitle', { field: t('road') })} />
    </>
  );
};
export default Create;
