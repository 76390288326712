import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CircularProgress,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';

const permissionsData = [
  'create_colorPalettes_all',
  'read_colorPalettes_all',
  'update_colorPalettes_all',
  'delete_colorPalettes_all',
  'create_entrances_all',
  'read_entrances_all',
  'update_entrances_all',
  'delete_entrances_all',
  'create_eventCategories_all',
  'read_eventCategories_all',
  'update_eventCategories_all',
  'delete_eventCategories_all',
  'create_events_all',
  'read_events_all',
  'update_events_all',
  'delete_events_all',
  'create_locationCategories_all',
  'read_locationCategories_all',
  'update_locationCategories_all',
  'delete_locationCategories_all',
  'create_locationCategoryRelations_all',
  'read_locationCategoryRelations_all',
  'update_locationCategoryRelations_all',
  'delete_locationCategoryRelations_all',
  'create_locations_all',
  'read_locations_all',
  'update_locations_all',
  'delete_locations_all',
  'create_maps_all',
  'read_maps_all',
  'update_maps_all',
  'delete_maps_all',
  'create_nodes_all',
  'read_nodes_all',
  'update_nodes_all',
  'delete_nodes_all',
  'create_notifications_all',
  'read_notifications_all',
  'update_notifications_all',
  'delete_notifications_all',
  'create_permissions_all',
  'read_permissions_all',
  'update_permissions_all',
  'delete_permissions_all',
  'create_pins_all',
  'read_pins_all',
  'update_pins_all',
  'delete_pins_all',
  'create_places_all',
  'read_places_all',
  'update_places_all',
  'delete_places_all',
  'create_relations_all',
  'read_relations_all',
  'update_relations_all',
  'delete_relations_all',
  'create_roles_all',
  'read_roles_all',
  'update_roles_all',
  'delete_roles_all',
  'create_schedules_all',
  'read_schedules_all',
  'update_schedules_all',
  'delete_schedules_all',
  'create_userRoles_all',
  'read_userRoles_all',
  'update_userRoles_all',
  'delete_userRoles_all',
  'create_visitDetails_all',
  'read_visitDetails_all',
  'update_visitDetails_all',
  'delete_visitDetails_all',
  'create_reviews_all',
  'create_reviews_me',
  'read_reviews_all',
  'read_reviews_me',
  'update_reviews_all',
  'update_reviews_me',
  'delete_reviews_all',
  'delete_reviews_me',
  'create_users_all',
  'create_users_me',
  'read_users_all',
  'read_users_me',
  'update_users_all',
  'update_users_me',
  'delete_users_all',
  'delete_users_me',
  'create_features_all',
  'read_features_all',
  'update_features_all',
  'delete_features_all',
  'update_lite_features_all',
  'create_roads_all',
  'read_roads_all',
  'update_roads_all',
  'delete_roads_all',
  'read_lite_roads_all',
  'update_lite_roads_all'
];

const RoleForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any }) => {
  const { t } = useTranslation();

  const { onSubmit, loading, title, defaultValues } = props;
  const permissions = {} as any;
  permissionsData.forEach((permission: any) => {
    const permissionInfo = permission.split('_');
    const accessTo = permissionInfo.pop();
    const resource = permissionInfo.pop();
    const action = permissionInfo.join('_');

    if (!permissions[resource]) {
      permissions[resource] = {};
    }
    permissions[resource][`${action}_${accessTo}`] = permission;
  });

  const defaultPermissions = defaultValues?.permissions?.reduce((acc: any, curr: string) => {
    acc[curr] = true;
    return acc;
  }, {});
  const [expanded, setExpanded] = useState('');
  const { handleSubmit, control } = useForm({
    defaultValues: { ...defaultValues, permissions: defaultPermissions || {} },
  });
  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;

        const permissions: string[] = [];
        Object.entries(dataToSubmit.permissions).forEach((entry) => {
          const key = entry[0];
          const value = entry[1];
          if (value === true) {
            permissions.push(key);
          }
        });
        dataToSubmit.permissions = permissions;

        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        {Object.entries(permissions).map((resource, rIndex) => (
          <div key={`div_${rIndex}`}>
            <ListItem
              key={`listItem_${rIndex}`}
              button
              onClick={() => (expanded === resource[0] ? setExpanded('') : setExpanded(resource[0]))}>
              <ListItemText primary={t(`${resource[0]}`)} />
              {expanded === resource[0] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse key={`collapse_${rIndex}`} in={expanded === resource[0]} timeout="auto">
              <List key={`list_${rIndex}`} component="div" disablePadding>
                {Object.entries(resource[1]).map((permission: any, pIndex) => (
                  <Controller
                    name={`permissions.${permission[1]}`}
                    control={control}
                    defaultValue={false}
                    key={`controller${rIndex}_${pIndex}`}
                    render={({ field: { onChange, value } }) => (
                      <ListItem onClick={() => onChange(!value)} button key={`listItem${rIndex}_${pIndex}`}>
                        <ListItemIcon>
                          <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                        </ListItemIcon>
                        <ListItemText primary={t(`${permission[0]}`)} />
                      </ListItem>
                    )}
                  />
                ))}
              </List>
            </Collapse>
          </div>
        ))}
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default RoleForm;
