import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleForm } from '../components';
import { useUpdateRoleMutation, useGetRoleQuery, UpdateRoleMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { roleId, placeId } = useParams();
  const { t } = useTranslation();
  const [updateRole, { data: updateData, loading: updateLoading }] = useUpdateRoleMutation();
  const { loading, data } = useGetRoleQuery({ variables: { id: roleId.toString() } });
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/roles`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdateRoleMutationVariables) => {
    updateRole({ variables: { ...dataToSubmit, id: roleId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <RoleForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('role') })}
        defaultValues={data?.roles[0]}
      />
    </>
  );
};
export default Update;
