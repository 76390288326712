import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from './Routes';
import './i18n';
import client from './ApolloClient';

function App() {
  const theme = createTheme();

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
