import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContactForm } from '../components';
import { useUpdateContactMutation, useGetContactQuery, UpdateContactMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { contactId, placeId } = useParams();
  const { t } = useTranslation();
  const [updateContact, { data: updateData, loading: updateLoading }] = useUpdateContactMutation();
  const { loading, data } = useGetContactQuery({ variables: { id: contactId.toString() } });
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/contacts`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdateContactMutationVariables) => {
    updateContact({ variables: { ...dataToSubmit, id: contactId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <ContactForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('contact') })}
        defaultValues={data?.contacts[0]}
      />
    </>
  );
};
export default Update;
