import en from './en';
import es from './es';

export default {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};
