import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

const NotificationForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any }) => {
  const { onSubmit, loading, title, defaultValues } = props;
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    description: yup.string().required(t('isRequired', { field: t('description') })),
    active: yup.boolean().required(t('isRequired', { field: t('active') })),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize('name')}
                type="text"
                variant="outlined"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize('description')}
                type="text"
                variant="outlined"
                {...register('description')}
                error={Boolean(errors.description)}
                helperText={errors.description?.message || ' '}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ textAlign: 'left' }}>
              <FormControlLabel
                control={
                  <Controller
                    name={'active'}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                    )}
                  />
                }
                label={capitalize(t('active'))}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default NotificationForm;
