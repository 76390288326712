import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { Grid, TextField } from '@mui/material';

const TextDescription = (props: { editable: boolean; item: any; setItem: any }) => {
  const { editable, item, setItem } = props;

  return editable ? (
    <Grid container spacing={1} direction="column">
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setItem({ ...item, title: e.target.value })}
          value={item.title}
          fullWidth
          label={'Title'}
          name="title"
          type="markdown"
          variant="outlined"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <MDEditor
          value={item.content}
          onChange={(value) => setItem({ ...item, content: value })}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <MDEditor.Markdown source={item.content} />
  );
};

export default TextDescription;
