import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScheduleForm } from '../components';
import { useCreateScheduleMutation, CreateScheduleMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';
import { forEach } from 'lodash';

const Create = () => {
  const { placeId, locationId } = useParams();
  const [createSchedule, { data, loading }] = useCreateScheduleMutation();
  const navigate = useNavigate();
  if (data) {
    navigate(`/places/${placeId}/locations/${locationId}/schedules`);
  }
  const { t } = useTranslation();
  const onSubmit = (dataToSubmit: CreateScheduleMutationVariables, daysOfTheWeek: any ) => {
    daysOfTheWeek.forEach((day: string) => {
      createSchedule({ variables: { ...dataToSubmit, dayOfTheWeek: day, locationId } });
    })
  };
  return (
    <>
      <ScheduleForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('schedule') })} create />
    </>
  );
};
export default Create;
