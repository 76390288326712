import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  InputLabel,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import ImageDropzone from '../../../common/components/ImageDropzone';
import { useParams } from 'react-router-dom';

const PinForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any }) => {
  const { onSubmit, loading, title, defaultValues } = props;
  const { t } = useTranslation();
  const { placeId } = useParams();
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    url: yup
      .string()
      .url()
      .required(t('isRequired', { field: t('url') })),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('name'))}
                type="text"
                variant="outlined"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message || ' '}
              />
            </Grid>

            <Grid item md={8} xs={12} sx={{ width: '100%' }}>
              <InputLabel sx={{ textAlign: 'left' }}>{capitalize(t('image'))}</InputLabel>
              <Controller
                control={control}
                name="url"
                render={({ field: { onChange, value } }) => (
                  <ImageDropzone
                    setFiles={(files) => onChange(files[0] || '')}
                    files={value ? [value] : []}
                    allowedFileTypes={['.svg']}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default PinForm;
