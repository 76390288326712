import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DynamicDescriptionItem from './DynamicDescriptionComponents/DynamicDescriptionItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CardContent } from '@mui/material';
import { colors } from '@mui/material';
import { capitalize } from 'lodash';
import { checkPermission } from '../../../utils/check-permission';

const DESCRIPTION_TYPES = {
  TEXT: 'markdown',
  CAROUSEL: 'carousel',
  VIDEO: 'video',
};

let counter = 0;
const getCounter = () => {
  counter += 1;
  return counter;
};

const DEFAULT_VALUES = {
  TEXT: {
    title: 'Descripción',
    type: DESCRIPTION_TYPES.TEXT,
    content: '',
    color: '',
    backgroundColor: '',
  },
  CAROUSEL: {
    type: DESCRIPTION_TYPES.CAROUSEL,
    title: 'Imágenes',
    images: [] as any,
  },
  VIDEO: {
    type: DESCRIPTION_TYPES.VIDEO,
    title: 'Video',
    url: '',
  },
};



const DynamicDescription = (props: { description: any[]; setDescription: any }) => {
  const { description, setDescription } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [defaultExpanded, setDefaultExpanded] = useState(false);
  const canUpdateLite = checkPermission('update_lite_features_all');

  const addDescriptionItem = async (defaultValues: any) => {
    if (!defaultExpanded) {
      setDefaultExpanded(true);
    }
    const newDescription = description.concat([
      { ...defaultValues, title: `${t(defaultValues.title)} (${getCounter()})`, key: uuid() },
    ]);
    setDescription(newDescription);
  };

  const setDescriptionItem = (newDescriptionItem: any, index: number) => {
    const newDescription = description;
    newDescription[index] = newDescriptionItem;
    setDescription(newDescription);
  };

  const deleteDescriptionItem = (index: number) => {
    const newDescription = description.filter((item, itemIndex) => itemIndex !== index);
    setDescription(newDescription);
  };

  const moveDescriptionItem = (from: number, to: number) => {
    const newDescription = description;
    const item = newDescription.splice(from, 1)[0];
    newDescription.splice(to, 0, item);
    setDescription(newDescription);
  };

  return (
    <>
      <CardContent
        style={{
          textAlign: 'left',
          fontSize: 14,
          color: '#aaa',
          fontWeight: 500,
        }}>
        {t('dynamicDescriptionExplain')}
      </CardContent>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Seleccionar tipo</DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            button
            onClick={() => {
              addDescriptionItem(DEFAULT_VALUES.TEXT);
              setOpen(false);
            }}>
            <ListItemText primary={capitalize(t('dynamicDescriptionText'))} />
          </ListItem>
          <ListItem
            button
            disabled={!canUpdateLite}
            onClick={() => {
              addDescriptionItem(DEFAULT_VALUES.CAROUSEL);
              setOpen(false);
            }}>
            <ListItemText primary={capitalize(t('dynamicDescriptionCarousel'))} secondary={!canUpdateLite && 'Solo planes de pago'} />
          </ListItem>
          <ListItem
            button
            disabled={!canUpdateLite}
            onClick={() => {
              addDescriptionItem(DEFAULT_VALUES.VIDEO);
              setOpen(false);
            }}>
            <ListItemText primary={capitalize(t('dynamicDescriptionVideo'))} secondary={!canUpdateLite && 'Solo planes de pago'} />
          </ListItem>
        </List>
      </Dialog>
      {description.map((item, index) => (
        <DynamicDescriptionItem
          key={item.key}
          descriptionItem={item}
          setDescriptionItem={(newDescriptionItem: any) => setDescriptionItem(newDescriptionItem, index)}
          deleteDescriptionItem={() => deleteDescriptionItem(index)}
          defaultExpanded={defaultExpanded}
          backgroundColor={index % 2 === 0 ? colors.grey[50] : colors.grey[100]}
          moveDescriptionItem={(relativeMovement: number) => moveDescriptionItem(index, index + relativeMovement)}
          moveUpEnabled={index > 0}
          moveDownEnabled={index < description.length - 1}
        />
      ))}
      <Box m={1} display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Button
          onClick={() => setOpen(true)}
          style={{ justifyContent: 'flex-end' }}
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<AddCircleIcon />}>
          {`${t('add')} ${t('description')}`}
        </Button>
      </Box>
    </>
  );
};
export default DynamicDescription;
