import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfographicsItem from './InfographicsItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import { v4 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import { CardContent } from '@mui/material';
import { colors } from '@mui/material';

let counter = 0;
const getCounter = () => {
  counter += 1;
  return counter;
};

const DEFAULT_VALUES = {
  title: 'Infographic',
  texts: [] as any[],
  image: {
    initialHeight: 100,
    finalHeight: 200,
    url: '',
  },
  backgroundColor: '',
};

const Infographics = (props: { infographics: any[]; setInfographics: any }) => {
  const { infographics, setInfographics } = props;
  const { t } = useTranslation();
  const [defaultExpanded, setDefaultExpanded] = useState(false);

  const addInfographicsItem = async () => {
    if (!defaultExpanded) {
      setDefaultExpanded(true);
    }
    const newInfographics = infographics.concat([
      { ...DEFAULT_VALUES, title: `${t(DEFAULT_VALUES.title)} (${getCounter()})`, key: uuid() },
    ]);
    setInfographics(newInfographics);
  };

  const setInfographicsItem = (newInfographicsItem: any, index: number) => {
    const newInfographics = infographics;
    newInfographics[index] = newInfographicsItem;
    setInfographics(newInfographics);
  };

  const deleteInfographicsItem = (index: number) => {
    const newInfographics = infographics.filter((_, itemIndex) => itemIndex !== index);
    setInfographics(newInfographics);
  };

  const moveInfographicsItem = (from: number, to: number) => {
    const newInfographics = infographics;
    const item = newInfographics.splice(from, 1)[0];
    newInfographics.splice(to, 0, item);
    setInfographics(newInfographics);
  };

  return (
    <>
      <CardContent
        style={{
          textAlign: 'left',
          fontSize: 14,
          color: '#aaa',
          fontWeight: 500,
        }}>
        {t('InfographicsExplain')}
      </CardContent>
      {infographics.map((item, index) => (
        <InfographicsItem
          key={item.key}
          infographicsItem={item}
          setInfographicsItem={(newInfographicsItem: any) => setInfographicsItem(newInfographicsItem, index)}
          deleteInfographicsItem={() => deleteInfographicsItem(index)}
          defaultExpanded={defaultExpanded}
          backgroundColor={index % 2 === 0 ? colors.grey[50] : colors.grey[100]}
          moveInfographicsItem={(relativeMovement: number) => moveInfographicsItem(index, index + relativeMovement)}
          moveUpEnabled={index > 0}
          moveDownEnabled={index < infographics.length - 1}
        />
      ))}
      <Box m={1} display="flex" justifyContent="flex-start" alignItems="flex-start">
        <Button
          onClick={() => addInfographicsItem()}
          style={{ justifyContent: 'flex-end' }}
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<AddCircleIcon />}>
          {`${t('add')} ${t('infographics')}`}
        </Button>
      </Box>
    </>
  );
};
export default Infographics;
