import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField, Card, CardHeader, CardContent, Grid, CardActions, CircularProgress } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import MapPreview from './MapPreview';
import { checkPermission } from '../../../utils/check-permission';

const MapForm = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any }) => {
  const { onSubmit, loading, title, defaultValues } = props;
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    floor: yup.number().required(t('isRequired', { field: t('floor') })),
    tileUrl: yup.string(),
    pointNe: yup.mixed().required(t('isRequired', { field: t('pointNe') })),
    pointSw: yup.mixed().required(t('isRequired', { field: t('pointSw') })),
    initialZoom: yup.number().required(t('isRequired', { field: t('initialZoom') })),
    minZoom: yup.number().required(t('isRequired', { field: t('minZoom') })),
    maxZoom: yup.number().required(t('isRequired', { field: t('maxZoom') })),
  });

  const canUpdateLite = checkPermission('update_lite_features_all');
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          initialZoom: defaultValues.initialZoom || 0,
          minZoom: defaultValues.minZoom || 0,
          maxZoom: defaultValues.maxZoom || 0,
          pointSw: { lat: defaultValues.pointSwGeo.latitude, lng: defaultValues.pointSwGeo.longitude },
          pointNe: { lat: defaultValues.pointNeGeo.latitude, lng: defaultValues.pointNeGeo.longitude },
        }
      : { floor: 0, initialZoom: 0, minZoom: 0, maxZoom: 0 },
  });
  useEffect(() => {
    if (!defaultValues || (defaultValues && (!defaultValues.pointNeGeo || !defaultValues.pointSwGeo))) {
      const setInitialValues = (currentPos: { lat: number; lng: number }) => {
        const ne = { lat: currentPos.lat + 0.005, lng: currentPos.lng + 0.005 };
        const sw = { lat: currentPos.lat - 0.005, lng: currentPos.lng - 0.005 };
        setValue('pointNe', ne);
        setValue('pointSw', sw);
        if (!defaultValues.minZoom || !defaultValues.maxZoom) {
          const { initialZoom, minZoom, maxZoom } = setRecommendedInitialZoom(ne, sw);
          setValue('initialZoom', initialZoom);
          setValue('minZoom', minZoom);
          setValue('maxZoom', maxZoom);
        }
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPos = { lat: position.coords.latitude, lng: position.coords.longitude };
          setInitialValues(currentPos);
        },
        (error) => {
          console.log('error on get initial position');
          console.log(error);
          const currentPos = { lat: -33.4, lng: -70.6 };
          setInitialValues(currentPos);
        },
      );
    }
  }, []);

  const watchFields = watch();
  useEffect(() => {
    const { initialZoom, minZoom, maxZoom } = (!watchFields.pointNe || !watchFields.pointSw)
      ? {initialZoom: watchFields.initialZoom, minZoom: watchFields.minZoom, maxZoom: watchFields.maxZoom}
      : setRecommendedInitialZoom(watchFields.pointNe, watchFields.pointSw);
    setValue('initialZoom', initialZoom);
    setValue('minZoom', minZoom);
    setValue('maxZoom', maxZoom);
  }, [watchFields.pointNe, watchFields.pointSw]);

  const setRecommendedInitialZoom = (ne: { lat: number; lng: number }, sw: { lat: number; lng: number }) => {
    const dist = Math.sqrt((ne?.lat - sw?.lat) ** 2 + (ne?.lng - sw?.lng) ** 2);
    const recommendedInitialZoom = Math.log2(1746.927616 / dist);
    const roundInitialZoom = Math.round(recommendedInitialZoom / 0.25) * 0.25;

    return {
      initialZoom: Math.min(roundInitialZoom, 21),
      minZoom: Math.min(roundInitialZoom - 1, 21),
      maxZoom: Math.min(roundInitialZoom + 3, 21),
    };
  };

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = JSON.parse(JSON.stringify(data));
        dataToSubmit.pointSw = [data.pointSw.lat, data.pointSw.lng];
        dataToSubmit.pointNe = [data.pointNe.lat, data.pointNe.lng];
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('name'))}
                type="text"
                variant="outlined"
                error={Boolean(errors.name)}
                helperText={errors.name?.message || ' '}
                {...register('name')}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('floor'))}
                type="number"
                variant="outlined"
                error={Boolean(errors.floor)}
                helperText={errors.floor?.message || ' '}
                {...register('floor')}
              />
            </Grid>
            {canUpdateLite && (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <TextField
                  fullWidth
                  label={capitalize(t('tileUrl'))}
                  type="text"
                  variant="outlined"
                  error={Boolean(errors.tileUrl)}
                  helperText={errors.tileUrl?.message || ' '}
                  {...register('tileUrl')}
                />
              </Grid>
            )}
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <MapPreview map={{ ...defaultValues, ...watchFields }} setValue={setValue} customTile={watchFields.tileUrl} />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('initialZoom'))}
                type="number"
                variant="outlined"
                error={Boolean(errors.initialZoom)}
                helperText={errors.initialZoom?.message || ' '}
                {...register('initialZoom')}
                inputProps={{ step: 0.25 }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('minZoom'))}
                type="number"
                variant="outlined"
                error={Boolean(errors.minZoom)}
                helperText={errors.minZoom?.message || ' '}
                {...register('minZoom')}
                inputProps={{ step: 0.25 }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('maxZoom'))}
                type="number"
                variant="outlined"
                error={Boolean(errors.maxZoom)}
                helperText={errors.maxZoom?.message || ' '}
                {...register('maxZoom')}
                inputProps={{ step: 0.25 }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};

export default MapForm;
