import { selectedPlace } from '../cache';
import { useReactiveVar } from '@apollo/client';

export function checkPermission(permission, placeId) {
    const selectedPlaceId = placeId === undefined ? useReactiveVar(selectedPlace) : placeId;
    // eslint-disable-next-line no-undef
    const user = JSON.parse(localStorage.getItem('user') || '{"permissions": {}}');
    const havePermission = (user.permissions[selectedPlaceId] || user.permissions['null'] || []).includes(permission);
    return havePermission;
}
