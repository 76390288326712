export default {
  contact: 'contacto',
  contacts: 'contactos',
  contactsDescription: 'Agrega todos posibles contactos que creas necesarios para asociarlos como información de contacto general o como información de los puntos de interés.',
  email: 'email',
  contactNumbers: 'números de contacto',
  phone: 'teléfono fijo',
  mobile: 'celular',
  whatsapp: 'WhatsApp',
  web: 'sitio web',
  socialMedias: 'redes sociales',
  displayName: 'nombre mostrado en "Creado por:"',
  createdBy: 'creado por',
  contactInfo: 'información de contacto',
};
