import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  Dialog,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import NodesEdit from './NodesEdit';
import { ErrorMessage } from '@hookform/error-message';
import { checkPermission } from '../../../utils/check-permission';

const Form = (props: { onSubmit: any; loading: boolean; title: string; defaultValues?: any; map: any }) => {
  const { onSubmit, loading, title, defaultValues, map } = props;
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    color: yup.string().required(t('isRequired', { field: t('color') })),
    width: yup
      .number()
      .required(t('isRequired', { field: t('width') }))
      .min(1),
    nodes: yup.array(yup.object()).required(t('nodesRequired')).min(2),
    unidirectional: yup.boolean(),
    visible: yup.boolean(),
  });

  const canUpdateLite = checkPermission('update_lite_features_all');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          nodes: defaultValues?.nodes?.map((node: any) => ({
            lat: node?.pointGeo?.latitude,
            lng: node?.pointGeo?.longitude,
          })),
        }
      : { name: '', color: '#8B572A', width: 3, nodes: [], unidirectional: false, visible: true },
  });
  const watchFields = watch();

  const [open, setOpen] = useState(false);
  const [testIntermediateNodes, setTestIntermediateNodes] = useState<boolean>(false);

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        onSubmit(dataToSubmit, testIntermediateNodes);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize(t('name'))}
                type="text"
                variant="outlined"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message || ' '}
              />
            </Grid>
            <Controller
              control={control}
              name="visible"
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ ml: '1rem' }}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label={t('visible')}
                />
              )}
            />
            {watchFields.visible && (
              <>
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    label={canUpdateLite ? t('roadWidth') : `${t('roadWidth')} (${t('payedPlans')})`}
                    type="number"
                    disabled={!canUpdateLite}
                    variant="outlined"
                    {...register('width')}
                    error={Boolean(errors.width)}
                    helperText={errors.width?.message || ' '}
                  />
                </Grid>
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="color"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          fullWidth
                          label={canUpdateLite ? t('color') : `${t('color')} (${t('payedPlans')})`}
                          type="text"
                          variant="outlined"
                          disabled={!canUpdateLite}
                          value={value}
                          error={Boolean(errors.color)}
                          helperText={errors.color?.message || ' '}
                          onClick={() => setOpen(true)}
                        />
                        <Dialog onClose={() => setOpen(false)} open={open}>
                          <SketchPicker color={value} onChange={(e: any) => onChange(e.hex)} />
                        </Dialog>
                      </>
                    )}
                  />
                </Grid>
              </>
            )}
            <Divider />
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <ErrorMessage
                errors={errors}
                name="nodes"
                render={({ message }) => <FormHelperText error={Boolean(message)}>{message}</FormHelperText>}
              />
              <Controller
                control={control}
                name="nodes"
                render={({ field: { onChange } }) => <NodesEdit map={map} road={watchFields} onChange={onChange} />}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <FormGroup>
                <Controller
                  control={control}
                  name="unidirectional"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ ml: '1rem' }}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                      label={t('unidirectionalDesc')}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ ml: '1rem' }}
                      onChange={() => {
                        setTestIntermediateNodes(!testIntermediateNodes);
                      }}
                      defaultChecked={testIntermediateNodes}
                    />
                  }
                  label={t('testIntermediateNodes')}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default Form;
