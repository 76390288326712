import React, { useEffect } from 'react';
import { Map } from '../../../graphql';
import DynamicList from '../../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDeleteMapMutation, useGetMapsQuery, refetchGetMapsQuery } from '../../../graphql';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import PlaceIcon from '@mui/icons-material/Place';
import { checkPermission } from '../../../utils/check-permission';
import { capitalize } from 'lodash';

const MapList = () => {
  const { placeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canCreateMap = checkPermission('create_maps_all');
  const canDeleteMap = checkPermission('delete_maps_all');

  const { loading, data } = useGetMapsQuery({ variables: { id: placeId } });
  const [deleteMap, { loading: deleteLoading }] = useDeleteMapMutation({
    refetchQueries: [refetchGetMapsQuery({ id: placeId })],
    awaitRefetchQueries: true,
  });
  const [selected, setSelected] = React.useState<Map>(null);
  useEffect(() => {
    if (selected) {
      Swal.fire({
        title: t('Are you sure?'),
        // eslint-disable-next-line quotes
        text: t(`cantRevertAction`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
      }).then((result) => {
        if (result.value) {
          Swal.showLoading();
          deleteMap({ variables: { id: selected.id } });
        }
        setSelected(null);
      });
    }
  }, [selected, deleteMap, t]);

  return (
    <div>
      <DynamicList
        loading={loading}
        description={t('mapsDescription')}
        actions={
          (
            <Button
              component={Link}
              to={`/places/${placeId}/maps/create`}
              variant="contained"
              color="primary"
              disabled={!canCreateMap}
              size="large"
              startIcon={<AddCircleIcon />}>
              {canCreateMap ?
                capitalize(t('create')) :
                `${capitalize(t('create'))} (${t('payedPlans')})`
              }
            </Button>
          )
        }
        objectList={data?.places[0].maps}
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => e.name,
          },
          {
            label: t('floor'),
            renderFn: (e: any) => e.floor,
          },
          {
            label: t('tileUrl'),
            renderFn: (e: any) => e.tileUrl,
          },
          {
            label: t('createdAt'),
            renderFn: (e: any) => moment(e.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: t('updatedAt'),
            renderFn: (e: any) => moment(e.updatedAt).format('DD/MM/YYYY'),
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('features')}>
                  <IconButton
                    sx={{ color: colors.blue[700] }}
                    onClick={() => navigate(`/places/${placeId}/maps/${e.id}/features`)}>
                    <PlaceIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('roads')}>
                  <IconButton
                    sx={{ color: colors.blue[700] }}
                    onClick={() => navigate(`/places/${placeId}/maps/${e.id}/roads`)}>
                    <EditRoadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => navigate(`/places/${placeId}/maps/${e.id}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                {
                  canDeleteMap && (
                    <Tooltip title={t('delete')}>
                      <IconButton sx={{ color: colors.red[600] }} onClick={() => setSelected(e)}>
                        {deleteLoading ? <CircularProgress /> : <DeleteIcon />}
                      </IconButton>
                    </Tooltip>
                  )
                }
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default MapList;
