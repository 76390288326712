import React, { useEffect } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { Layout as DashboardLayout } from './layouts/dashboard/layout';
import { Layout as AuthLayout } from './layouts/auth/layout';
import AppBreadcrumbs from './common/components/AppBreadcrumbs';
import { ErrorSnackbar } from './common/components';
import contactsRoutes from './views/contacts/routes';
import placesRoutes from './views/places/routes';
import usersRoutes from './views/users/routes';
import rolesRoutes from './views/roles/routes';
import featuresRoutes from './views/features/routes';
import schedulesRoutes from './views/schedules/routes';
import loginRoutes from './views/login/routes';
import mapsRoutes from './views/maps/routes';
import locationsRoutes from './views/locations/routes';
import notificationsRoutes from './views/notifications/routes';
import pinsRoutes from './views/pins/routes';
import roadsRoutes from './views/roads/routes';
import locationCategoriesRouter from './views/locationCategories/routes';
import { accessToken } from './cache';
import mapFeaturesRoutes from './views/mapFeatures/routes';
import Graphiql from './Graphiql';
import { checkPermission } from './utils/check-permission';

locationsRoutes.children.push({ path: ':locationId', children: [featuresRoutes, schedulesRoutes] });

mapsRoutes.children.push({ path: ':mapId', children: [roadsRoutes, mapFeaturesRoutes] });

placesRoutes.children.push({
  path: ':placeId',
  children: [mapsRoutes, locationsRoutes, notificationsRoutes, rolesRoutes, pinsRoutes, contactsRoutes, locationCategoriesRouter],
});

const routes = [placesRoutes, loginRoutes, usersRoutes, { path: '*', element: <div>404</div> }];

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const element = useRoutes(routes);
  const showPins = checkPermission('create_pins_all');
  const showNotifications = checkPermission('create_notifications_all');
  const showUsers = checkPermission('read_users_all', null);
  const showRoles = checkPermission('read_roles_all', null);

  const shouldRenderRoute = () => {
    if ((location.pathname.includes(notificationsRoutes.path)) && !showNotifications) {
      return false;
    } else if ((location.pathname.includes(pinsRoutes.path)) && !showPins) {
      return false;
    } else if ((location.pathname.includes(rolesRoutes.path)) && !showRoles) {
      return false;
    } else if ((location.pathname.includes(usersRoutes.path)) && !showUsers) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (!accessToken() && location.pathname !== '/login') {
      navigate('/login', { replace: true });
    } else if (location.pathname === '/') {
      navigate('/places', { replace: true });
    } else if(!shouldRenderRoute()){
      navigate('/places', { replace: true });
    }
  }, [location.pathname]);
  if (location.pathname === '/login') {
    return (
      <AuthLayout>
        <ErrorSnackbar />
        {element}
      </AuthLayout>
    );
  } else if (location.pathname === '/graphiql') {
    return <Graphiql />;
  }
  return (
    <DashboardLayout>
      <AppBreadcrumbs />
      <ErrorSnackbar />
      {element}
    </DashboardLayout>
  );
};

export default AppRoutes;
