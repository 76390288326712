import React from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation, CreateUserMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  CardActions,
  CircularProgress,
  capitalize,
  InputLabel,
} from '@mui/material';

const Create = () => {
  const [createUser, { data, loading }] = useCreateUserMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (data) {
    navigate('/users');
  }
  const onSubmit = (dataToSubmit: CreateUserMutationVariables) => {
    createUser({ variables: dataToSubmit });
  };
  const schema = yup.object().shape({
    email: yup.string().email(),
    password: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data);
      })}>
      <Card>
        <CardHeader title="Crear un usuario" />
        <CardContent>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label={capitalize(t('email'))}
              type="text"
              variant="outlined"
              {...register('email')}
              error={Boolean(errors.email)}
              helperText={errors.email?.message || ' '}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label={capitalize(t('password'))}
              type="password"
              variant="outlined"
              {...register('password')}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{ position: 'relative', margin: 1 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default Create;
