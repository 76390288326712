import { useExplorerPlugin } from '@graphiql/plugin-explorer';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { GraphiQL } from 'graphiql';
import { useState } from 'react';
import React from 'react';
import Box from '@mui/material/Box';

import 'graphiql/graphiql.css';
import '@graphiql/plugin-explorer/dist/style.css';

const fetcher = createGraphiQLFetcher({
  url: process.env.REACT_APP_API_URL,
});

const DEFAULT_QUERY = '';

const Graphiql = () => {
  const [query, setQuery] = useState(DEFAULT_QUERY);
  const explorerPlugin = useExplorerPlugin({
    query,
    onEdit: setQuery,
  });
  const onTabChange = (elem: any) => {
    const { tabs, activeTabIndex } = elem;
    const currentQuery = tabs[activeTabIndex];
    setQuery(currentQuery);
  };
  return (
    <Box height="100vh" textAlign="left" overflow="auto">
      <GraphiQL fetcher={fetcher} onTabChange={onTabChange} query={query} plugins={[explorerPlugin]} />
    </Box>
  );
};

export default Graphiql;
