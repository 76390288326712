import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContactForm } from '../components';
import { useCreateContactMutation, CreateContactMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Create = () => {
  const [createContact, { data, loading }] = useCreateContactMutation();
  const { placeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (data) {
    navigate(`/places/${placeId}/contacts`);
  }

  const onSubmit = (dataToSubmit: CreateContactMutationVariables) => {
    createContact({ variables: { ...dataToSubmit, placeId } });
  };
  return <>{<ContactForm onSubmit={onSubmit} loading={loading} title={t('createTitle', { field: t('contact') })} />}</>;
};
export default Create;
