import React from 'react';
import DynamicList from '../../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useGetRolesQuery } from '../../../graphql';

const RoleList = () => {
  const { placeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { loading, data } = useGetRolesQuery({ variables: { placeId } });

  return (
    <div>
      <DynamicList
        loading={loading}
        objectList={data?.roles}
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => t(e.name),
          },
          {
            label: t('createdAt'),
            renderFn: (e: any) => moment(e.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: t('updatedAt'),
            renderFn: (e: any) => moment(e.updatedAt).format('DD/MM/YYYY'),
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => navigate(`/places/${placeId}/roles/${e.id}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default RoleList;
