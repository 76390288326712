import React, { useEffect } from 'react';
import { Feature } from '../../graphql';
import DynamicList from '../../common/components/DynamicList';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, colors, ButtonGroup, Tooltip } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDeleteFeatureMutation, useGetFeaturesLazyQuery, useGetMapZoomsQuery, refetchGetMapQuery } from '../../graphql';

const FeatureList = () => {
  const { placeId, mapId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  const { loading: mapLoading, data: mapData } = useGetMapZoomsQuery({ variables: { id: mapId } });
  const [getFeatures, { loading, data }] = useGetFeaturesLazyQuery();
  const [deleteFeature, { loading: deleteLoading }] = useDeleteFeatureMutation({
    refetchQueries: [refetchGetMapQuery({ id: mapId })],
    awaitRefetchQueries: true,
  });
  const [selected, setSelected] = React.useState<Feature>(null);

  const dict: any = {
    0: 'Baja',
    1: 'Media Baja',
    2: 'Media',
    3: 'Media Alta',
    4: 'Alta',
  };

  const searchFeatures = (search = '') => {
    getFeatures({ variables: {mapId, search} });
  }

  useEffect(() => {
    const searchParam = urlParams.get('search');
    searchFeatures(searchParam || '');
  }, [urlParams]);

  const features = data?.features;
  const map = mapData?.maps[0];
  useEffect(() => {
    if (selected) {
      Swal.fire({
        title: t('Are you sure?'),
        // eslint-disable-next-line quotes
        text: t(`cantRevertAction`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('Yes'),
      }).then((result) => {
        if (result.value) {
          Swal.showLoading();
          deleteFeature({ variables: { id: selected.id } });
        }
        setSelected(null);
      });
    }
  }, [selected, deleteFeature, t]);

  return (
    <div>
      <DynamicList
        loading={loading && mapLoading}
        description={t('featuresDescription')}
        search={searchFeatures}
        actions={
          <Button
            component={Link}
            to={`/places/${placeId}/maps/${mapId}/features/create`}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddCircleIcon />}>
            {t('create')}
          </Button>
        }
        objectList={features}
        columns={[
          {
            label: t('name'),
            renderFn: (e: any) => e.name,
          },
          {
            label: t('location'),
            renderFn: (e: any) => e.location?.name,
          },
          {
            label: t('toZoom'),
            renderFn: (e: any) => (!e.toZoom || e.toZoom >= map?.maxZoom)
              ? 'Sin limite'
              : dict[(map?.maxZoom - (e.toZoom || 21)) / ((map?.maxZoom - map?.minZoom) / (Object.keys(dict).length - 1))],
          },
          {
            label: t('fromZoom'),
            renderFn: (e: any) => (!e.fromZoom || e.fromZoom <= map?.minZoom)
              ? 'Sin limite'
              : dict[(map?.maxZoom - (e.fromZoom || 0)) / ((map?.maxZoom - map?.minZoom) / (Object.keys(dict).length - 1))],
          },
          {
            label: t('createdAt'),
            renderFn: (e: any) => moment(e.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: t('updatedAt'),
            renderFn: (e: any) => moment(e.updatedAt).format('DD/MM/YYYY'),
          },
          {
            label: t('actions'),
            renderFn: (e: any) => (
              <ButtonGroup>
                <Tooltip title={t('update')}>
                  <IconButton
                    sx={{ color: colors.yellow[800] }}
                    onClick={() => navigate(`/places/${placeId}/maps/${mapId}/features/${e.id}/update`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton sx={{ color: colors.red[600] }} onClick={() => setSelected(e)}>
                    {deleteLoading ? <CircularProgress /> : <DeleteIcon />}
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
        ]}
      />
    </div>
  );
};

export default FeatureList;
