export default {
  schedulesDescription:
    'The schedules are used to show visitors the entry and exit time of each day at the point of interest (if they exist), one must be created for each day of the week in which entry is allowed.',
  schedule: 'schedule',
  schedules: 'schedules',
  dayOfTheWeek: 'day of the week',
  specificDay: 'specific day',
  active: 'active',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
  sunday: 'sunday',
};
