export default {
  locationsDescription:
    'Los puntos de interés representan una entidad especifica del lugar, la cual puede tener una o multiples ubicaciones, ya sea una actividad, atracción, baños, etc.',
  location: 'punto de interés',
  locations: 'puntos de interés',
  canReview: 'se puede calificar',
  logoUrl: 'Logo',
  testIntermediateNodes:
    'Buscar relaciones intermedias en el camino (esto puede ralentizar mucho la creación/edición del camino, pero crea automáticamente las relaciones con otros caminos que pasen a traves de este, se recomienda que cuando este parámetro esté activo, sea un camino con pocos nodos)',
  dynamicDescription: 'Descripción Dinámica',
};
