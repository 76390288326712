import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, TextField, Card, CardHeader, CardContent, CardActions, CircularProgress } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import { useLoginMutation, useMeLazyQuery } from '../../graphql';
import { accessToken, refreshToken } from '../../cache';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});
const SignIn = () => {
  const navigate = useNavigate();
  const [login, { data, loading: loginLoading }] = useLoginMutation();
  const [me, { data: meData, loading }] = useMeLazyQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const client = useApolloClient();

  const onSubmit = (data: any) => {
    login({ variables: data });
  };
  useEffect(() => {
    if (data?.login?.login?.idToken) {
      localStorage.setItem('accessToken', data.login.login.idToken);
      accessToken(data.login.login.idToken);
      localStorage.setItem('refreshToken', data.login.login.refreshToken);
      refreshToken(data.login.login.refreshToken);
      me();
    }
  }, [data, navigate]);
  useEffect(() => {
    if (meData?.me?.id) {
      const user: any = { ...meData.me };
      const roles = user.roles.map((e: any) => e.name);
      if (!roles.some((role: any) => ['owner', 'owner_lite', 'owner_pro', 'owner_free', 'superadmin'].includes(role))) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        client.clearStore();
        accessToken('');
        refreshToken('');
        // TODO: use a toast to say unauthorized
        navigate('/login', { replace: true });
        return;
      }
      const permissions: any = {};
      user.roles.forEach((role: any) => {
        if (!permissions[role.placeId]) permissions[role.placeId] = [];
        permissions[role.placeId] = [...permissions[role.placeId], ...role.permissions].filter(
          (v, i, a) => a.indexOf(v) === i,
        );
      });
      user.roles = roles;
      user.permissions = permissions;
      localStorage.setItem('user', JSON.stringify(user));
      navigate('/', { replace: true });
    }
  }, [meData, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent={'center'}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardHeader title="Login" />
            <CardContent>
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                {...register('email')}
                error={Boolean(errors.email)}
                helperText={errors.email?.message || ' '}
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                {...register('password')}
                error={Boolean(errors.password)}
                helperText={errors.password?.message || ' '}
              />
            </CardContent>
            <CardActions>
              <div>
                <Button
                  sx={{ position: 'relative', margin: 1 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loginLoading || loading}>
                  Login
                </Button>
                {loginLoading ||
                  (loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                    />
                  ))}
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignIn;
