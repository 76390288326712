import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapForm } from '../components';
import { useUpdateMapMutation, useGetMapQuery, UpdateMapMutationVariables } from '../../../graphql';
import { useTranslation } from 'react-i18next';

const Update = () => {
  const { mapId, placeId } = useParams();
  const [updateMap, { data: updateData, loading: updateLoading }] = useUpdateMapMutation();
  const { loading, data } = useGetMapQuery({ variables: { id: mapId.toString() } });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      navigate(`/places/${placeId}/maps`);
    }
  }, [updateData]);

  const onSubmit = (dataToSubmit: UpdateMapMutationVariables) => {
    updateMap({ variables: { ...dataToSubmit, id: mapId } });
  };

  if (loading) {
    return <></>;
  }
  return (
    <>
      <MapForm
        onSubmit={onSubmit}
        loading={updateLoading}
        title={t('updateTitle', { field: t('map') })}
        defaultValues={data?.maps[0]}
      />
    </>
  );
};
export default Update;
